import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import useAxios from 'axios-hooks'
import { AppContext } from '../../context/app'
import SearchTable from 'src/components/searchTable'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { isEmpty } from 'src/views/utils/helper'
import { CButton } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from 'src/views/context/GlobalState'
import CIcon from '@coreui/icons-react'
import { cilEnvelopeLetter } from '@coreui/icons'
import ReceiptModal from '../depositMoney/receiptModal'

const transactionHistoryDetails = [
  {
    id: '649a99e0d701454243a222a9',
    account: '6491f77900a615238dcb6e05',
    amount: '20',
    type: 'DEBIT',
    status: 'PENDING',
    balance: '20,000',
    date: '2023-06-27T08:12:16.579Z',
    transactionScreenshot: 'path/to/transaction-screenshot',
    success: true,
  },
  {
    id: '649a47a68846440f1a4e9c0f',
    account: '6491f77900a615238dcb6e05',
    amount: '20',
    type: 'CREDIT',
    status: 'PENDING',
    balance: '30,000',
    date: '2023-06-27T02:21:26.928Z',
    transactionScreenshot: 'path/to/transaction-screenshot',
    success: true,
  },
]

const MyTransactionHistory = () => {
  const { setLoader } = useContext(AppContext)
  const { userObj } = useContext(GlobalContext)
  const accountId = localStorage.getItem('accountId')
  const navigate = useNavigate()
  const [transactionDetails, setTransactionDetails] = useState([])
  const [receiptModalData, setReceiptModalData] = useState({})
  let isInvestor = userObj?.accountRole !== 'FUNDRAISER'

  const [
    { data: transactionHistoryDetails, loading: transactionHistoryLoading },
    transactionHistory,
  ] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/escrow-transactions`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    transactionHistory()
  }, [])

  useEffect(() => {
    setLoader(transactionHistoryLoading)
  }, [transactionHistoryLoading])

  useEffect(() => {
    if (!isEmpty(transactionHistoryDetails) && transactionHistoryDetails[0].success) {
      setTransactionDetails(transactionHistoryDetails)
    }
  }, [transactionHistoryDetails])

  const tableConfig = [
    {
      header: 'Date',
      dataCell: (info) => moment.utc(info.date).format('DD-MM-YYYY'),
      width: 20,
    },
    {
      header: 'Amount',
      dataCell: (info) => info.amount,
      width: 15,
    },
    {
      header: 'Type',
      dataCell: (info) => info.type,
      width: 15,
    },
    {
      header: 'Status',
      dataCell: (info) => info.status,
      width: 12,
    },
    {
      header: 'Details',
      dataCell: (info) => `-`,
      width: 25,
    },
    {
      header: <div className="text-center">{'Receipt'}</div>,
      dataCell: (info) => (
        <div className="text-center">
          <CIcon
            onClick={() => {
              console.log('setReceiptModalData', info)
              setReceiptModalData(info)
            }}
            icon={cilEnvelopeLetter}
            className="cP"
            style={{ width: '20px', height: '20px' }}
          />
        </div>
      ),
      width: 8,
    },
  ]
  return (
    <div className="mt-5 px-5">
      <div className="w-100 d-flex justify-content-between">
        <h4 style={{ fontSize: '1.5625vw', fontWeight: '600' }} className="font-bold">
          Transaction History
        </h4>
        {isInvestor && (
          <CButton onClick={() => navigate('/deposit-money')} className="text-white" color="info">
            + Deposit
          </CButton>
        )}
      </div>
      <SearchTable
        type={`Transaction`}
        tableCardHeight={65}
        marginDisable={false}
        disableButton={true}
        disableSearch={true}
        tableContent={transactionDetails}
        tableConfig={tableConfig}
      />
      {!isEmpty(receiptModalData) && (
        <ReceiptModal data={receiptModalData} onClose={() => setReceiptModalData({})} />
      )}
    </div>
  )
}

export default MyTransactionHistory
