import React, { useState } from 'react'
import { CButton, CFormInput, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import { raiseToast } from 'src/views/utils/helper'
// import useUploadS3Hook from 'src/s3upload/useUploadS3Hook'
// import ClipboardCopy from 'src/components/copyToClipboard'

const DepositModal = ({ onConfirm, onCancel }) => {
  // const { handleUploadFiles } = useUploadS3Hook()
  const [amount, setAmount] = useState(0)
  const [fees, setFees] = useState(0)

  // const handleFileChange = async (event) => {
  //   let fileObj = event.target.files
  //   let uploadStatus = await handleUploadFiles([fileObj])

  //   if (uploadStatus.success) {
  //     setImagePaths(uploadStatus.data[0].s3Key)
  //   }
  // }

  const handleSave = () => {
    // if (!imagePaths) {
    //   raiseToast('error', 'Please Upload Transaction Receipt!')
    //   return
    // }
    if (Number(amount) < 1) {
      raiseToast('error', 'Please Enter a Valid Amount!')
      return
    }

    onConfirm({
      amount: parseFloat(amount + fees).toFixed(2),
      type: 'CREDIT',
      transactionScreenshot: '/',
    })
  }

  return (
    <CModal size="lg" visible={true} alignment="center">
      <CModalHeader className="px-5" closeButton={false}>
        <p style={{ fontSize: '1.2vw', fontWeight: '600' }}>{'DEPOSIT MONEY'} </p>
      </CModalHeader>
      <CModalBody className="px-5 ">
        {/* <div className="">
          <p style={{ fontSize: '1.1vw', fontWeight: '600' }}>{'Account Number'} </p>
          <ClipboardCopy copyText={'123456789987654321'} />
        </div>
        <div className="mt-4">
          <p style={{ fontSize: '1.1vw', fontWeight: '600' }}>{'IFSC Code'} </p>
          <ClipboardCopy copyText={'SBINN0012345'} />
        </div> */}
        <div className="mt-4 mb-4">
          <p style={{ fontSize: '1.1vw', fontWeight: '600' }}>{'Deposit Amount (OMR)'} </p>
          <CFormInput
            value={amount}
            type="number"
            onChange={(e) => setAmount(Number(e.target.value))}
          />
        </div>
        <div className="mt-4 mb-4">
          <p style={{ fontSize: '1.1vw', fontWeight: '600' }}>{'Fees (OMR)'} </p>
          <CFormInput value={fees.toFixed(2)} readOnly disabled={true} />
        </div>
        <div className="mt-4 mb-4">
          <p style={{ fontSize: '1.1vw', fontWeight: '600' }}>{'Total (Deposit Amount + Fees)'}</p>
          <CFormInput value={parseFloat(amount + fees).toFixed(2)} readOnly disabled={true} />
        </div>
        {/* <div className="mt-4 mb-3">
          <p className="" style={{ fontSize: '1.1vw', fontWeight: '600' }}>
            Upload Transaction Receipt
          </p>
          <CFormInput
            id="formFile"
            // accept=""
            type="file"
            onChange={(e) => handleFileChange(e)}
          />
        </div> */}
      </CModalBody>
      <CModalFooter className="px-5">
        <CButton className="text-white mx-3 px-4" color="info" onClick={onCancel}>
          Cancel
        </CButton>
        <CButton className="text-white px-4" color="info" onClick={handleSave}>
          Confirm
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default DepositModal
