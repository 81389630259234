import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilPuzzle, cilSpeedometer } from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import ProgressIcon from './assets/progress-icon.svg'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    type: 'FUNDRAISER',
  },
  {
    component: CNavItem,
    name: 'Offerings',
    to: '/my-offerings',
    // icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    icon: <img src={ProgressIcon} className="nav-icon" />,
    type: 'FUNDRAISER',
  },
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    type: 'INVESTOR',
  },

  {
    component: CNavItem,
    name: 'Offerings',
    to: '/all-offerings',
    // icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    icon: <img src={ProgressIcon} className="nav-icon" />,
    type: 'INVESTOR',
  },
  {
    component: CNavItem,
    name: 'My Wallet',
    to: '/my-wallet',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    type: 'BOTH',
  },
  {
    component: CNavItem,
    name: 'Transaction',
    to: '/transaction-history',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    type: 'BOTH',
  },
  {
    component: CNavItem,
    name: 'Order Details',
    to: '/order-details',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    type: 'BOTH',
  },
  {
    component: CNavItem,
    name: 'Withdraw Money',
    to: '/withdraw-money',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    type: 'FUNDRAISER',
  },
  {
    component: CNavItem,
    name: 'Expenses',
    to: '/expenses',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    type: 'FUNDRAISER',
  },

  // {
  //   component: CNavItem,
  //   name: 'INVESTOR',
  //   to: '/setting',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   type: 'INVESTOR',
  // },
  // FOR BOTH
  // {
  //   component: CNavItem,
  //   name: 'BOTH',
  //   to: '/setting',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   type: 'BOTH',
  // },
]

export default _nav
