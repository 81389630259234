import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CListGroupItem,
  CListGroup,
} from '@coreui/react'
import useAxios from 'axios-hooks'
import React, { useContext, useEffect, useState } from 'react'
import { FaFilePdf, FaFileWord, FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import useUploadS3Hook from 'src/s3upload/useUploadS3Hook'
import { AppContext } from 'src/views/context/app'
import { isEmpty, raiseToast } from 'src/views/utils/helper'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { styled } from 'styled-components'
import { TiDelete } from 'react-icons/ti'
import { MdDeleteForever } from 'react-icons/md'
const headingStyle = {
  fontSize: '1.1vw',
  fontWeight: '600',
  color: '#5C4C73',
}

const Wrapper = styled.div`
  padding: 32px 0;
  .mb6 {
    margin-bottom: 6px;
  }
`

const CancelButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #fff;
    border: 2px solid #233e5f;
  }
`

const NextButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  background: #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #233e5f90;
    border: 2px solid #233e5f;
  }
`
const PlusBtn = styled(CButton)`
  padding: 5px 15px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  background: #233e5f;
  border-radius: 6px;
  color: #233e5f;
  &:hover {
    background: #233e5f90;
    border: 2px solid #233e5f;
  }
`
const CreateOffering = () => {
  const navigate = useNavigate()
  const { handleUploadFiles } = useUploadS3Hook()

  const [selectOwner, setSelectOwner] = useState('')
  const [contactPoint, setContactPoint] = useState('')
  const [offeringName, setOfferingName] = useState('')
  const [estimateEPR, setEstimateEPR] = useState('')
  const [openDate, setOpenDater] = useState('')
  const [closeDate, setCloseDate] = useState('')
  const [capitalType, setCapitalType] = useState('EQUITY')
  const [creditScore, setCreditScore] = useState('')
  const [numberShares, setNumberShares] = useState(0)
  const [pricePerShare, setPricePerShare] = useState(0)
  const [fundingGoal, setFundingGoal] = useState(0)
  const [maxShare, setMaxShare] = useState(0)
  const [minShare, setMinShare] = useState(0)
  const [rePaymentPeriod, setRePaymentPeriod] = useState(0)
  const [rePaymentType, setRePaymentType] = useState('')
  const [maxLoan, setMaxLoan] = useState(0)
  const [minLoan, setMinLoan] = useState(0)
  const [shortDesc, setShortDesc] = useState('')
  const [longDesc, setLongDesc] = useState('')
  const [socialLink, setSocialLink] = useState('')
  const [imagePaths, setImagePaths] = useState('')
  const accountId = localStorage.getItem('accountId')
  const { setLoader } = useContext(AppContext)
  const [showVidoes, setShowVidoes] = useState(false)
  const [showDocs, setShowDocs] = useState(false)
  const [showImgs, setShowImgs] = useState(false)
  const [files, setFiles] = useState([])
  const [imgFiles, setImgFiles] = useState([])
  const [videoFiles, setVideoFiles] = useState([])
  const [docType, setDocType] = useState('')

  // ==============================docs=================================
  const handleFileChange1 = (event) => {
    const fileList = event.target.files
    setFiles([...files, ...fileList])
  }
  const handleRemoveFile1 = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }
  // ================================imgs======================================
  const handelOnChangeImgs = (e) => {
    const selectedImg = e.target.files
    setImgFiles([...imgFiles, ...selectedImg])
  }
  const handleRemoveImg = (index) => {
    const images = [...imgFiles]
    images.splice(index, 1)
    setImgFiles(images)
  }
  // =================================vids=====================================
  const handleOnChangeVideos = (e) => {
    const selectedVid = e.target.files
    setVideoFiles([...videoFiles, ...selectedVid])
  }
  const handleRemoveVid = (i) => {
    const videos = [...videoFiles]
    videos.splice(i, 1)
    setVideoFiles(videos)
  }
  // ===================================api call================================
  const [{ data: createData, loading: createLoading }, createCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/offerings`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(createLoading)
  }, [createLoading])

  useEffect(() => {
    if (createData && createData.success) {
      raiseToast('success', 'Offering Created Succesfully!!')
      navigate('/my-offerings')
    }
  }, [createData])
  // const [selectOwner, setSelectOwner] = useState('')

  const handleSave = async () => {
    const fields = [
      { name: 'Owner', value: selectOwner, setter: setSelectOwner },
      { name: 'Contact Point', value: contactPoint, setter: setContactPoint },
      { name: 'Offering Name', value: offeringName, setter: setOfferingName },
      { name: 'Open Date', value: openDate, setter: setOpenDater },
      { name: 'Close Date', value: closeDate, setter: setCloseDate },
      { name: 'Capital Type', value: capitalType, setter: setCapitalType },
      { name: 'Credit Score', value: creditScore, setter: setCreditScore },
      { name: 'Number of Shares', value: numberShares, setter: setNumberShares },
      { name: 'Price Per Share', value: pricePerShare, setter: setPricePerShare },
      { name: 'Funding Goal', value: fundingGoal, setter: setFundingGoal },
      { name: 'Estimate EPR', value: estimateEPR, setter: setEstimateEPR },
      { name: 'Max Share', value: maxShare, setter: setMaxShare },
      { name: 'Min Share', value: minShare, setter: setMinShare },
      { name: 'Short Description', value: shortDesc, setter: setShortDesc },
      { name: 'Long Description', value: longDesc, setter: setLongDesc },
      { name: 'Social Link', value: socialLink, setter: setSocialLink },
    ]
    let firstEmptyField = fields.find((field) => !field.value)
    if (firstEmptyField) {
      raiseToast('error', `${firstEmptyField.name} is required!`)
      firstEmptyField.setter('')
      return
    }
    let patchPayload = {
      owner: selectOwner,
      contactPoint: contactPoint,
      name: offeringName,
      openDate: openDate,
      closeDate: closeDate,
      capitalType: capitalType,
      creditScore: creditScore,
      sharesCount: numberShares,
      pricePerShare: pricePerShare,
      fundingGoal: fundingGoal,
      liquidatingPercentage: estimateEPR,
      maxCommitmentShares: maxShare,
      minCommitmentShares: minShare,
      shortDescription: shortDesc,
      longDescription: longDesc,
      imagePaths: [imagePaths],
      socialUrls: [socialLink],
    }
    await createCall({ data: patchPayload })
  }
  // =================================== modal for upload video===================================
  const [modalOpenVid, setModalOpenVid] = useState(false)

  const videoModalClose = () => {
    setModalOpenVid(false)
  }
  const videoModalOpen = () => {
    setModalOpenVid(true)
  }
  // =================================== modal for upload docs===================================
  const [modalOpenDoc, setModalOpenDoc] = useState(false)

  const openDocModal = () => {
    setModalOpenDoc(true)
  }
  const closeDocModal = () => {
    setModalOpenDoc(false)
  }
  // =================================== modal for upload images===================================
  const [modalOpenImg, setModalOpenImg] = useState(false)

  const openImgModal = () => {
    setModalOpenImg(true)
  }
  const closeImgModal = () => {
    setModalOpenImg(false)
  }
  return (
    <Wrapper>
      <div>
        <p className="fs24 fwSB mx-3 mb24">Basic Details</p>
        <div className="mx-3">
          <CRow>
            <CCol>
              <p className="mb6" style={headingStyle}>
                SELECT OWNER*
              </p>
              <CFormInput
                type="text"
                value={selectOwner}
                onChange={(e) => setSelectOwner(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CONTACT POINT
              </p>
              <CFormInput
                type="text"
                value={contactPoint}
                onChange={(e) => setContactPoint(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                OFFERING NAME*
              </p>
              <CFormInput
                type="text"
                value={offeringName}
                onChange={(e) => setOfferingName(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                ESTIMATES EPR %*
              </p>
              <CFormInput
                type="text"
                value={estimateEPR}
                onChange={(e) => setEstimateEPR(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAMPAIGN OPEN DATE
              </p>
              <CFormInput
                type="date"
                value={openDate}
                onChange={(e) => setOpenDater(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAMPAIGN CLOSE DATE *
              </p>
              <CFormInput
                type="date"
                value={closeDate}
                onChange={(e) => setCloseDate(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAPITAL TYPE *
              </p>
              <CFormSelect value={capitalType} onChange={(e) => setCapitalType(e.target.value)}>
                <option value={'EQUITY'}>EQUITY</option>
                <option value={'DEBT'}>DEBT</option>
              </CFormSelect>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CREDIT SCORE
              </p>
              <CFormInput value={creditScore} onChange={(e) => setCreditScore(e.target.value)} />
            </CCol>
          </CRow>
          {capitalType === 'EQUITY' ? (
            <>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    NUMBER OF SHARES*
                  </p>
                  <CFormInput
                    type="number"
                    value={numberShares}
                    onChange={(e) => setNumberShares(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    PRICE PER SHARE (OMR)
                  </p>
                  <CFormInput
                    type="number"
                    value={pricePerShare}
                    onChange={(e) => setPricePerShare(e.target.value)}
                  />
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    FUNDING GOAL (OMR)
                  </p>
                  <CFormInput
                    type="number"
                    value={fundingGoal}
                    onChange={(e) => setFundingGoal(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    MAX COMMITMENT,SHARES
                  </p>
                  <CFormInput
                    type="number"
                    value={maxShare}
                    onChange={(e) => setMaxShare(e.target.value)}
                  />
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    MIN COMMITMENT,SHARES
                  </p>
                  <CFormInput
                    type="number"
                    value={minShare}
                    onChange={(e) => setMinShare(e.target.value)}
                  />
                </CCol>
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    Social Link*
                  </p>
                  <CFormInput
                    type="text"
                    value={socialLink}
                    onChange={(e) => setSocialLink(e.target.value)}
                  />
                </CCol>
              </CRow>
            </>
          ) : (
            <>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    Repayment Period*
                  </p>
                  <CFormInput
                    type="number"
                    value={rePaymentPeriod}
                    onChange={(e) => setRePaymentPeriod(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    Repayment Period Type
                  </p>
                  <CFormSelect
                    value={rePaymentType}
                    onChange={(e) => setRePaymentType(e.target.value)}
                  >
                    <option value={'Invoice Financing'}>Invoice Financing</option>
                    <option value={'Cashflow Financing'}>Cashflow Financing</option>
                    <option value={'Working Capital Financing'}>Working Capital Financing</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    FUNDING GOAL (OMR)
                  </p>
                  <CFormInput
                    type="number"
                    value={fundingGoal}
                    onChange={(e) => setFundingGoal(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    Max. Loan Ticket
                  </p>
                  <CFormInput
                    type="number"
                    value={maxLoan}
                    onChange={(e) => setMaxLoan(e.target.value)}
                  />
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    Min. Loan Ticket
                  </p>
                  <CFormInput
                    type="number"
                    value={minLoan}
                    onChange={(e) => setMinLoan(e.target.value)}
                  />
                </CCol>
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    Social Link*
                  </p>
                  <CFormInput
                    type="text"
                    value={socialLink}
                    onChange={(e) => setSocialLink(e.target.value)}
                  />
                </CCol>
              </CRow>
            </>
          )}

          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Short Description
              </p>
              <CFormTextarea
                type="text"
                rows={4}
                value={shortDesc}
                onChange={(e) => setShortDesc(e.target.value)}
              />
            </CCol>
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Long Description
              </p>
              <CFormTextarea
                type="text"
                rows={4}
                value={longDesc}
                onChange={(e) => setLongDesc(e.target.value)}
              />
            </CCol>
          </CRow>
          {/* =================================VIDEOS==================================== */}
          <CRow>
            <div className="d-flex justify-content-between align-items-center mt-3 ">
              <CFormLabel className="mb6" style={headingStyle}>
                UPLOAD VIDEOS
              </CFormLabel>
              <PlusBtn title="Add Videos" onClick={videoModalOpen} className="fs16 fwSB text-white">
                <FaPlus />
              </PlusBtn>
            </div>
          </CRow>
          {showVidoes ? (
            <CListGroup>
              {videoFiles.map((file, index) => (
                <CListGroupItem
                  style={{ paddingLeft: '100px' }}
                  className="d-flex gap-3 align-items-center mt-2"
                  key={index}
                >
                  <video width="140" height="100" controls>
                    <source src={URL.createObjectURL(file)} type={file.type} />
                  </video>
                  <p>{file.name}</p>
                  <CButton
                    className="text-danger bg-transparent  border-0 fwSB fs24 "
                    size="sm"
                    onClick={() => handleRemoveVid(index)}
                  >
                    <MdDeleteForever />
                  </CButton>
                </CListGroupItem>
              ))}
            </CListGroup>
          ) : (
            ''
          )}
          {/* ===================================== modal ======================================= */}

          <CModal visible={modalOpenVid} onClose={videoModalClose}>
            <CModalHeader closeButton></CModalHeader>
            <CModalBody>
              <div className="mt-4">
                <p className="mb6" style={headingStyle}>
                  UPLOAD VIDEOS*
                </p>
                <CFormInput
                  type="file"
                  accept="video/*"
                  id="vid"
                  onChange={handleOnChangeVideos}
                  multiple
                />
                <div>
                  {videoFiles.map((file, index) => (
                    <div className="m-2" key={index}>
                      {file.name}
                      <TiDelete
                        style={{
                          color: 'red',
                          fontSize: '20px',
                          cursor: 'pointer',
                          margin: '0 10px',
                        }}
                        onClick={() => handleRemoveVid(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </CModalBody>
            <CModalFooter>
              <PlusBtn
                onClick={() => {
                  videoModalClose()
                  setShowVidoes(true)
                  console.log('fhgbn')
                }}
                className="fs16 me-3 fwSB text-white"
                shape="rounded-pill"
              >
                SAVE
              </PlusBtn>
            </CModalFooter>
          </CModal>

          {/* =================================DOCS==================================== */}
          <CRow>
            <div className="d-flex justify-content-between align-items-center mt-3 ">
              <CFormLabel className="mb6" style={headingStyle}>
                UPLOAD DOCUMENTS
              </CFormLabel>
              <PlusBtn
                title="Add more files"
                onClick={openDocModal}
                className="fs16 fwSB text-white"
              >
                <FaPlus />
              </PlusBtn>
            </div>
          </CRow>
          {showDocs ? (
            <CListGroup className="mt-2">
              {files.map((file, index) => (
                <CListGroupItem className="mb-1" key={index}>
                  {file.name.toLowerCase().endsWith('.pdf') ? (
                    <>
                      <FaFilePdf className="h4 text-danger" /> {/* Display PDF icon */}
                    </>
                  ) : (
                    <>
                      <FaFileWord className="h4 text-primary" /> {/* Display DOC icon */}
                    </>
                  )}
                  <span>{file.name}</span>
                  <CButton
                    className="text-danger bg-transparent  border-0 fwSB fs24 "
                    size="sm"
                    onClick={() => handleRemoveFile1(index)}
                  >
                    <MdDeleteForever />
                  </CButton>
                </CListGroupItem>
              ))}
            </CListGroup>
          ) : (
            ''
          )}

          {/* ===================================== modal ======================================= */}

          <CModal visible={modalOpenDoc} onClose={closeDocModal}>
            <CModalHeader closeButton></CModalHeader>
            <CModalBody>
              <CCol>
                <p className="mb6" style={headingStyle}>
                  DOCUMENT TYPE *
                </p>
                <CFormSelect value={docType} onChange={(e) => setDocType(e.target.value)}>
                  <option value={'Company Document'}>Company Document</option>
                  <option value={'Pitch Deck'}>Pitch Deck</option>
                  <option value={'Feasibility Study / Report'}>Feasibility Study / Report</option>
                </CFormSelect>
              </CCol>
              <div className="mt-4">
                <p className="mb6" style={headingStyle}>
                  UPLOAD DOCUMENTS*
                </p>
                <CFormInput
                  type="file"
                  id="docs"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange1}
                  multiple
                />
                {
                  <div>
                    {files.map((file, index) => (
                      <div className="m-2" key={index}>
                        {file.name}
                        <TiDelete
                          style={{
                            color: 'red',
                            fontSize: '20px',
                            cursor: 'pointer',
                            margin: '0 10px',
                          }}
                          onClick={() => handleRemoveFile1(index)}
                        />
                      </div>
                    ))}
                  </div>
                }
              </div>
            </CModalBody>
            <CModalFooter>
              <PlusBtn
                onClick={() => {
                  closeDocModal()
                  setShowDocs(true)
                }}
                className="fs16 me-3 fwSB text-white"
                shape="rounded-pill"
              >
                SAVE
              </PlusBtn>
            </CModalFooter>
          </CModal>

          {/* =================================IMAGES==================================== */}
          <CRow>
            <div className="d-flex justify-content-between align-items-center mt-3 ">
              <CFormLabel className="mb6" style={headingStyle}>
                UPLOAD IMAGES
              </CFormLabel>
              <PlusBtn
                title="Add more files"
                onClick={openImgModal}
                className="fs16 fwSB text-white"
              >
                <FaPlus />
              </PlusBtn>
            </div>
          </CRow>
          {showImgs ? (
            <CListGroup className="mt-2">
              {imgFiles.map((file, index) => (
                <CListGroupItem className="mt-1" key={index}>
                  <img src={URL.createObjectURL(file)} alt={file.name} width="70" height="70" />
                  <span className="m-5 ">{file.name}</span>
                  <CButton
                    className="text-danger bg-transparent  border-0 fwSB fs24 "
                    size="sm"
                    onClick={() => handleRemoveVid(index)}
                  >
                    <MdDeleteForever />
                  </CButton>
                </CListGroupItem>
              ))}
            </CListGroup>
          ) : (
            ''
          )}
          {/* ===================================== modal ======================================= */}

          <CModal visible={modalOpenImg} onClose={closeImgModal}>
            <CModalHeader closeButton></CModalHeader>
            <CModalBody>
              <div className="mt-4">
                <p className="mb6" style={headingStyle}>
                  UPLOAD IMAGES*
                </p>
                <CFormInput
                  type="file"
                  id="img"
                  accept="image/*"
                  onChange={handelOnChangeImgs}
                  multiple
                />
                <div>
                  {imgFiles.map((file, index) => (
                    <div className="m-2" key={index}>
                      {file.name}
                      <TiDelete
                        style={{
                          color: 'red',
                          fontSize: '20px',
                          cursor: 'pointer',
                          margin: '0 10px',
                        }}
                        onClick={() => handleRemoveImg(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </CModalBody>
            <CModalFooter>
              <PlusBtn
                onClick={() => {
                  closeImgModal()
                  setShowImgs(true)
                }}
                className="fs16 me-3 fwSB text-white"
                shape="rounded-pill"
              >
                SAVE
              </PlusBtn>
            </CModalFooter>
          </CModal>

          {/* <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Upload Documents
              </p>
              <CFormInput
                id="formFile1"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                onChange={(e) => handleFileChange(e)}
              />
            </CCol>
          </CRow> */}
        </div>

        <div className="dF jcFE mx-3 mt-5">
          <CancelButton
            className="fs16 fwSB baW me-3 text-black"
            variant="outline"
            shape="rounded-pill"
            onClick={() => navigate('/my-offerings')}
          >
            CANCEL
          </CancelButton>
          <NextButton
            onClick={handleSave}
            className="fs16 me-3 fwSB text-white"
            shape="rounded-pill"
          >
            SAVE
          </NextButton>
          <NextButton onClick={handleSave} className="fs16 fwSB text-white" shape="rounded-pill">
            PUBLISH
          </NextButton>
        </div>
      </div>
    </Wrapper>
  )
}

export default CreateOffering
