import React, { useContext, useEffect, useState } from 'react'
import { CButton } from '@coreui/react'
import { styled } from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty } from 'src/views/utils/helper'
import { AppContext } from 'src/views/context/app'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import useAxios from 'axios-hooks'
import moment from 'moment'
import { FaEdit } from 'react-icons/fa'
import { ROUTES } from 'src/appContainer/PathRoutes'
const Wrapper = styled.div`
  padding: 32px 24px;
  .mb6 {
    margin-bottom: 6px;
  }
  .ml24 {
    margin-left: 24px;
  }
  .cPurple {
    color: #5c4c73;
  }
  .w70 {
    width: 70%;
  }
`

const AddNewButton = styled(CButton)`
  padding: 10px 24px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  background: #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #233e5f90;
    border: 2px solid #233e5f;
  }
`

const OfferingCard = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid rgba(35, 62, 95, 0.5);
  span {
    color: #233e5f;
  }
`

const ViewLink = styled(Link)`
  color: #dc8331;
`

const MyOffering = () => {
  const navigate = useNavigate()
  const [offeringDetails, setOfferingDetails] = useState([])
  const { setLoader } = useContext(AppContext)
  const accountId = localStorage.getItem('accountId')

  const [{ data: offeringData, loading: offeringLoading }, offeringCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/offerings`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    offeringCall()
  }, [])

  useEffect(() => {
    setLoader(offeringLoading)
  }, [offeringLoading])

  useEffect(() => {
    if (offeringData && !isEmpty(offeringData)) {
      setOfferingDetails(offeringData)
    }
  }, [offeringData])
  return (
    <Wrapper>
      <div className="dFA jcSB mb40">
        <p className="fs24 fwSB cPurple mb6">MY OFFERINGS</p>
        <AddNewButton
          className="dFA text-white"
          shape="rounded-pill"
          onClick={() => navigate('/create-offering')}
        >
          Add New
          <img src="/icons/add.svg" alt="add.svg" className="mx-1" />
        </AddNewButton>
      </div>
      {offeringDetails.map((item, index) => {
        return (
          <OfferingCard key={index}>
            <p className="mb6">
              This Offering Opens on&nbsp;
              <span className="fwSB">{moment.utc(item.openDate).format('DD MMM')}.</span>
            </p>
            <div className="dFA jcSB">
              <div className="dFA w70">
                {item?.imagePaths && (
                  <img
                    height={110}
                    width={110}
                    src={`https://fundt1.s3.amazonaws.com/${item?.imagePaths[0]}`}
                    alt="img"
                  />
                )}
                <div className="ml24">
                  <p className="mb12 fwSB">{item.name}</p>
                  <p className="line-clamp-2">{item.shortDescription}</p>
                </div>
              </div>
              <FaEdit
                onClick={() => navigate(ROUTES.editOffferings, { state: { record: item } })}
                style={{ cursor: 'pointer' }}
              />
              <ViewLink to={`/offering-details/${item.id}`}>
                <u>View Details</u>
              </ViewLink>
            </div>
          </OfferingCard>
        )
      })}
    </Wrapper>
  )
}

export default MyOffering
