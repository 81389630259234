import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import { useForm } from 'react-hook-form'
import DropDownErrorMessage from '../common/dropDownErrorMessage'
import TextField from '../textField/textField'
import useAxios from 'axios-hooks'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { AppContext } from 'src/views/context/app'

const FieldLabel = styled.div`
  p {
    font-size: 16px;
  }
`

const ExpenseForm = styled.form`
  overflow-y: auto;
`

const AddExpenseModal = ({ onClose, onAddExpense }) => {
  const { setLoader } = useContext(AppContext)
  const accountId = localStorage.getItem('accountId')

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    // getValues,
  } = useForm()

  const validateAccountNumber = (value) => {
    const expectedAccountNumber = watch('accountNumber')

    if (value === expectedAccountNumber) {
      return true
    }
    return 'Account number does not match'
  }

  const [{ data: expenseData, loading: expenseLoading }, expenseCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/expenses`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(expenseLoading)
  }, [expenseLoading])

  useEffect(() => {
    if (expenseData?.success) {
      onAddExpense()
    }
  }, [expenseData])

  const onSubmit = (data) => {
    expenseCall({
      data: {
        name: data.expenseName,
        bankName: data.bankName,
        bankBranch: data.branch,
        bankBenificiaryName: data.beneficiaryName,
        bankAccountNumber: data.accountNumber,
        bankSwiftCode: data.swiftCode,
        type: 'Vendor',
      },
    })
  }

  return (
    <CModal scrollable size="lg" visible={true}>
      <CModalHeader
        className="px-5 d-flex flex-column align-items-center justify-content-center position-relative"
        closeButton={false}
      >
        <p style={{ fontSize: '1.2vw', fontWeight: '600' }}>Add Expense</p>
        <p style={{ fontSize: '14px', color: 'gray' }}>All Fields are Mandatory</p>
        <CIcon
          icon={cilX}
          style={{ width: '24px', height: '24px' }}
          className="position-absolute end-0 top-0 mx-4 my-4 cP"
          onClick={onClose}
        />
      </CModalHeader>
      <ExpenseForm onSubmit={handleSubmit(onSubmit)}>
        <CModalBody className="px-5 w-100">
          <div className="w-100">
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Expense Name</p>
              <TextField
                id="expenseName"
                label="Expense Name"
                type="text"
                name="expenseName"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Expense Name',
                  minLength: {
                    value: 3,
                    message: 'Expense name Should be of atleast 3 characters',
                  },
                }}
                required
              />
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Address</p>
              <TextField
                id="address"
                label="Address"
                type="text"
                name="Address"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Address',
                }}
                required
              />
            </FieldLabel>
            {/* <FieldLabel className=" w100 mb40">
              <p className="fs16 mb16 fwB">Category</p>
              <Controller
                name="category"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <CustomSelect placeholder="Payment Method" {...field} options={categoryItems} />
                  )
                }}
              />
              {errors.category && (
                <DropDownErrorMessage>Please Select Category</DropDownErrorMessage>
              )}
            </FieldLabel> */}
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Bank Name</p>
              <TextField
                id="bankName"
                label="Bank Name"
                type="text"
                name="bankName"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Bank Name',
                }}
                required
              />
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Branch</p>
              <TextField
                id="branch"
                label="Branch"
                type="text"
                name="branch"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Branch Name',
                }}
                required
              />
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Benificiary Name</p>
              <TextField
                id="benificiaryName"
                label="Benificiary Name"
                type="text"
                name="beneficiaryName"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Beneficiary Name',
                }}
                required
              />
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Account Number</p>
              <TextField
                id="accountNumber"
                label="Account Number"
                type="text"
                name="accountNumber"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Account Number',
                }}
                required
              />
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Confirm Account Number</p>
              <TextField
                id="confirmAccountNumber"
                label="Confirm Account Number"
                type="text"
                name="confirmAccountNumber"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Account Number',
                  validate: validateAccountNumber,
                }}
                required
              />
              {errors.confirmAccountNumber && (
                <DropDownErrorMessage>{errors.confirmAccountNumber.message}</DropDownErrorMessage>
              )}
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">Swift Code</p>
              <TextField
                id="swiftCode"
                label="Swift Code"
                type="text"
                name="swiftCode"
                errors={errors}
                register={register}
                validationSchema={{
                  required: 'Please Enter Swift Code',
                }}
                required
              />
            </FieldLabel>
          </div>
        </CModalBody>
        <CModalFooter className="px-5 d-flex justify-content-center">
          <CButton className="text-white px-4" color="info" type="submit">
            Submit
          </CButton>
        </CModalFooter>
      </ExpenseForm>
    </CModal>
  )
}

export default AddExpenseModal
