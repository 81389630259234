import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'

import FundtLogo from '../assets/fundt-logo.png'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import { GlobalContext } from 'src/views/context/GlobalState'
import { isEmpty } from 'src/views/utils/helper'

const AppSidebar = () => {
  const { userObj } = useContext(GlobalContext)
  const [navOptions, setNavOptions] = useState([])
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  useEffect(() => {
    if (!isEmpty(userObj) && userObj?.accountRole) {
      let tempOptions = navigation.filter(
        (info) => `${userObj?.accountRole}`.includes(info.type) || info.type === 'BOTH',
      )
      setNavOptions(tempOptions)
    }
  }, [userObj])
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <div>
          <img src={FundtLogo} alt="logo" className="w-100 h-fit" />
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navOptions} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
