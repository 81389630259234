import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import DropDownErrorMessage from 'src/components/common/dropDownErrorMessage'
import CustomSelect from 'src/components/customSelect/customSelect'
// import TextField from 'src/components/textField/textField'
import DepositModal from './depositModal'
import { isEmpty } from 'src/views/utils/helper'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { AppContext } from 'src/views/context/app'
import ReceiptModal from './receiptModal'

const Wrapper = styled.div`
  padding: 60px 108px;
  /* margin-left: auto; */
  width: 75%;
  .w30 {
    width: 30%;
  }
  .mr34 {
    margin-right: 34px;
  }
`

const FieldLabel = styled.div`
  p {
    font-size: 16px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 66px;
  color: #35a2d1;
`

const NextButton = styled.button`
  color: #fff;
  background: #35a2d1;
  border-radius: 5px;
  padding: 10px 32px;
  border: none;
`

const UploadField = styled.div`
  input {
    border: 1px solid red;
  }
  cursor: pointer;
`
// const CustomLink = styled(Link)`
//   color: #35a2d1;
//   font-size: 16px;
//   font-weight: bold;
// `

const paymentMethod = [
  { value: 'bank_transfer', label: 'BANK TRANSFER' },
  // { value: 'payment_salary', label: 'PAYMENT SALARY' },
  // { value: 'payment_supplier_1', label: 'PAYMENT SUPPLIER 1' },
  // { value: 'payment_supplier_2', label: 'PAYMENT SUPPLIER 2' },
  // { value: 'payment_supplier_3', label: 'PAYMENT SUPPLIER 3' },
  // { value: 'pay_rent', label: 'PAY RENT' },
  // { value: 'pay_utility_bills', label: 'PAY UTILITY BILLS' },
  // { value: 'pay_government_fees', label: 'PAY GOVERNMENT FEES' },
]

const DepositMoney = () => {
  const [depositModal, setDepositModal] = useState(false)
  const [receiptModalData, setReceiptModalData] = useState({})
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    // getValues,
  } = useForm()
  const navigate = useNavigate()
  const accountId = localStorage.getItem('accountId')
  const { setLoader } = useContext(AppContext)

  const [{ data: createData, loading: createLoading }, createCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/escrow-transactions`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (createData && createData.success) {
      setDepositModal(false)
      setReceiptModalData(createData)
      // navigate('/transaction-history')
    }
  }, [createData])

  useEffect(() => {
    setLoader(createLoading)
  }, [createLoading])

  const onSubmit = async (data) => {
    // console.log('onSubmit', data)
    // if (Number(data.amount) < 1) {
    //   raiseToast('error', 'Please Enter a Valid Amount!')
    //   return
    // }
    setDepositModal(true)
  }

  const handleCreate = async (payload) => {
    await createCall({ data: payload })
  }

  return (
    <>
      <Wrapper>
        <p className="fs36 fwB mb40">DEPOSIT MONEY</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldLabel className=" w100 mb40">
            <p className="fs16 mb16 fwB">
              <u>PAYMENT METHOD</u>
            </p>
            <Controller
              name="payment_method"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <CustomSelect placeholder="Payment Method" {...field} options={paymentMethod} />
                )
              }}
            />
            {errors.payment_method && (
              <DropDownErrorMessage>Payment Method is required</DropDownErrorMessage>
            )}
          </FieldLabel>
          {/* <FieldLabel className="w100 mb32">
            <p className="fs16 mb16 fwB">
              <u>AMOUNT</u>
            </p>
            <TextField
              id="amount"
              label="Amount"
              type="number"
              name="amount"
              errors={errors}
              currencyCode="omr"
              register={register}
              validationSchema={{
                required: 'Deposit a minimum of 1 OMR',
                minLength: {
                  value: 1,
                  message: 'Deposit a minimum of 1 OMR',
                },
              }}
              required
            />
          </FieldLabel> */}
          <div className="d-flex">
            <ButtonWrapper className="dFA jcSB">
              <NextButton type="submit" onClick={() => navigate('/transaction-history')}>
                BACK
              </NextButton>
            </ButtonWrapper>
            <ButtonWrapper className="dFA jcSB mx-4">
              <NextButton type="submit">NEXT STEP</NextButton>
            </ButtonWrapper>
          </div>
        </form>
      </Wrapper>
      {depositModal && (
        <DepositModal
          amount={depositModal}
          onConfirm={handleCreate}
          onCancel={() => setDepositModal(false)}
        />
      )}
      {!isEmpty(receiptModalData) && (
        <ReceiptModal data={receiptModalData} onClose={() => setReceiptModalData({})} />
      )}
    </>
  )
}

export default DepositMoney
