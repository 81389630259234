import React, { useContext, useEffect, useState } from 'react'
import {
  CCard,
  CButton,
  CModalTitle,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CFormFeedback,
} from '@coreui/react'
import { styled } from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { getImageUrl, isEmpty, raiseToast } from 'src/views/utils/helper'
import { AppContext } from 'src/views/context/app'
import { ACCOUNT_URL, ALL_OFFERING } from 'src/views/utils/urls'
import useAxios from 'axios-hooks'
import moment from 'moment'
import CustomImage from 'src/components/customImage/customImage'
import { GlobalContext } from 'src/views/context/GlobalState'
import InvestModal from './investModal'

const Wrapper = styled.div`
  padding: 32px 24px;
  .mb6 {
    margin-bottom: 6px;
  }
  .ml24 {
    margin-left: 24px;
  }
  .ml150 {
    margin-left: 150px;
  }
  /* TODO: need to set these colors in theme */
  .cBlue {
    color: #233e5f;
  }
  .cOrange {
    color: #dc8331;
  }
  .c333 {
    color: #333;
  }
  .cPurple {
    color: #5d3fd390;
  }
  .gap300 {
    gap: 300px;
  }
`

const OfferingDetailCard = styled(CCard)`
  width: 100%;
  border-radius: 32px;
  padding: 34px 24px;
  border-bottom: 1px solid rgba(35, 62, 95, 0.5);
  span {
    color: #233e5f;
  }
  ul {
    list-style-type: none;
  }
`

const FundDetailCard = styled(CCard)`
  width: 50%;
  border-radius: 50px;
  padding: 36px 28px;
  margin-right: 45px;
  color: #fff;
  border: none;
  background: #fff;
`

const ShareCard = styled(CCard)`
  border-radius: 50px;
  padding: 16px 45px;
  width: 42%;
  color: #fff;
  border: none;
  background: #fff;
`

const InvestersModal = styled(CModal)`
  .cOrange {
    color: #dc8331;
  }
  .ml24 {
    margin-left: 24px;
  }
  .investerCard {
    width: 100%;
    border-radius: 60px;
    padding: 8px 14px;
  }
  .partitionBorder {
    border-bottom: 3px solid #d9d9d9;
  }
`

const ProgressButton = styled(CButton)`
  background: #02c19f;
  color: #fff;
  border: none;
  padding: 10px 24px;
  &:hover {
    background: #02c19f;
  }
`

const investerModalDetails = [
  {
    src: '/assets/offering-man.svg',
    title: 'XX Company',
    month: 'May',
    date: '22, 2023',
    time: '5:30PM',
  },
  {
    src: '/assets/offering-man.svg',
    title: 'XX Company',
    month: 'May',
    date: '22, 2023',
    time: '5:30PM',
  },
  {
    src: '/assets/offering-man.svg',
    title: 'XX Company',
    month: 'May',
    date: '22, 2023',
    time: '5:30PM',
  },
  {
    src: '/assets/offering-man.svg',
    title: 'XX Company',
    month: 'May',
    date: '22, 2023',
    time: '5:30PM',
  },
  {
    src: '/assets/offering-man.svg',
    title: 'XX Company',
    month: 'May',
    date: '22, 2023',
    time: '5:30PM',
  },
]

const Data = '-'
const OfferingSDetails = () => {
  const { offeringId } = useParams()
  const navigate = useNavigate()
  const [readMore, setReadMore] = useState(false)
  // const [minimumShareValue, setMinimumShareValue] = useState('')
  // const [minimumShareAmount, setMinimumShareAmount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [showInvestModal, setShowInvestModal] = useState(false)
  const [offeringDetails, setOfferingDetails] = useState({})
  // const [isValidShares, setIsValidShares] = useState(true)
  const { setLoader } = useContext(AppContext)
  const { userObj } = useContext(GlobalContext)
  const accountId = localStorage.getItem('accountId')
  let isInvestor = userObj?.accountRole !== 'FUNDRAISER'
  const [{ data: offeringData, loading: offeringLoading }, offeringCall] = useAxios(
    {
      url: isInvestor
        ? `${ALL_OFFERING}/${offeringId}`
        : `${ACCOUNT_URL}/${accountId}/offerings?offeringId=${offeringId}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    offeringCall()
  }, [])

  useEffect(() => {
    setLoader(offeringLoading)
  }, [offeringLoading])

  useEffect(() => {
    if (offeringData && !isEmpty(offeringData)) {
      setOfferingDetails(isInvestor ? offeringData : offeringData[0])
    }
  }, [offeringData])

  return (
    <Wrapper>
      <div className={`dFA jcC fs16 mb6 cBlue ${isInvestor && 'mb-4'}`}>
        <div className={`dFA jcSB w100`}>
          <div
            className="dFA fwSB cP  start-0 text-center"
            onClick={() => (!isInvestor ? navigate('/my-offerings') : navigate('/all-offerings'))}
          >
            <span className="fs24 text-center mb-2">&#x2190;</span>
            <span className="mx-1">Back</span>
          </div>
          {isInvestor && (
            <div onClick={() => setShowInvestModal(!showInvestModal)}>
              <CButton
                style={{ background: '#02C19F', border: '1px solid #02C19F' }}
                className="text-white"
                shape="rounded-pill"
              >
                Invest
              </CButton>
            </div>
          )}
          {!isInvestor && (
            <i className=" fSB">
              Note : This Summary of Offering Shows how your Investors will see the data{' '}
            </i>
          )}
        </div>
      </div>
      {/* <div className="dFA jcFE mb32">
        <img src="/icons/edit-icon.svg" />
        <img src="/icons/delete-icon.svg" />
      </div> */}
      <OfferingDetailCard className="mb32">
        <div className="dF jcSB mb24">
          <div className="dFA">
            <img
              style={{
                width: '90px',
                height: '90px',
                borderRadius: '50%', // Makes the element a circle
              }}
              src={
                offeringDetails?.imagePaths
                  ? getImageUrl(offeringDetails?.imagePaths[0])
                  : '/assets/offering-details.svg'
              }
              alt="img"
            />
            <div className="ml24">
              <p className="fs24 fwSB mb12">{offeringDetails?.owner}</p>
              <p className="fs20 cBlue mb12">{offeringDetails?.name}</p>
              <p className="fs20 cBlue">
                This Offering Close at {moment.utc(offeringDetails?.closeDate).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className="cP cOrange" onClick={() => setVisible(!visible)}>
            <u>who invested in</u>
          </div>
        </div>
        <div className="dF jcSB">
          <div>
            <p>{offeringDetails?.longDescription}</p>
            {/* <p>{offeringDetails?.longDescription?.substring(0, 1400)}</p>
            {!readMore && (
              <div className="taR cP cOrange" onClick={() => setReadMore((prev) => !prev)}>
                <u>Read More</u>
              </div>
            )} */}
          </div>
          <div
          // style={{
          //   width: '27.34375vw',
          //   aspectRatio: 15 / 10,
          //   background: '#828D9380',
          //   overflow: 'hidden',
          //   borderRadius: '20px',
          // }}
          // className="ml24 dFA jcC"
          >
            {/* <img src="/assets/offering-details.svg" /> */}
            {/* <img
              src={
                offeringDetails?.imagePaths
                  ? getImageUrl(offeringDetails?.imagePaths[0])
                  : '/assets/offering-details.svg'
              }
              alt="thubnail img"
            /> */}

            {/* <CustomImage
              imageUrl={
                offeringDetails?.imagePaths
                  ? getImageUrl(offeringDetails?.imagePaths[0])
                  : '/assets/offering-details.svg'
              }
              imgClass="w-full object-contain"
              imgAlt={'thubnail img'}
            /> */}
          </div>
        </div>
        {/* {readMore && (
          <p>
            {offeringDetails?.longDescription?.substring(
              1401,
              offeringDetails?.longDescription?.length,
            )}
          </p>
        )}
        {readMore && (
          <div className="taR cP cOrange" onClick={() => setReadMore((prev) => !prev)}>
            <u>Show Less</u>
          </div>
        )} */}
      </OfferingDetailCard>

      {/* <OfferingDetailCard className="mb32">
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Idea Of Project</p>
          <p className="c333">This is the brief about the project</p>
        </div>
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Products/Services</p>
          <div className="dF">
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">List of Products/Services</li>
              <li className="mb6">Project 1</li>
              <li className="mb6">Project 2</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Selling Price</li>
              <li className="mb6">$20</li>
              <li className="mb6">$25</li>
            </ul>
          </div>
        </div>
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Raw Material</p>
          <div className="dF">
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Raw Material Item</li>
              <li className="mb6">item 1</li>
              <li className="mb6">item 2</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Required Units/Months</li>
              <li className="mb6">2</li>
              <li className="mb6">3</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Cost Per Unit</li>
              <li className="mb6">$20</li>
              <li className="mb6">$30</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Suppliers</li>
              <li className="mb6">Supplier 1</li>
              <li className="mb6">Supplier 2</li>
            </ul>
          </div>
        </div>
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Market Details</p>
          <div className="dF">
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Targeted Group</li>
              <li className="mb6">Targeted Group 1</li>
              <li className="mb6">Targeted Group 2</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Individual Organisations</li>
              <li className="mb6">IO 1</li>
              <li className="mb6">IO 2</li>
            </ul>
          </div>
        </div>
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Targeted Market</p>
          <ul style={{ width: '25%' }}>
            <li className="mb6">Targeted Market 1</li>
            <li className="mb6">Targeted Market 2</li>
            <li className="mb6">Targeted Market 3</li>
          </ul>
        </div>
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Competitors</p>
          <div className="dF">
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Competitor Company</li>
              <li className="mb6">Competitor Company 1</li>
              <li className="mb6">Competitor Company 2</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Products/Services</li>
              <li className="mb6">Product 1</li>
              <li className="mb6">Products 2</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Location</li>
              <li className="mb6">Location 1</li>
              <li className="mb6">Location 2</li>
            </ul>
          </div>
        </div>
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Approvals and Permits</p>
          <div className="dF">
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Approvals and Permits</li>
              <li className="mb6">Permit 1</li>
              <li className="mb6">Permit 2</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Issued On</li>
              <li className="mb6">2023-03-16</li>
              <li className="mb6">2023-09-15</li>
            </ul>
            <ul style={{ width: '25%' }}>
              <li className="mb12 cPurple fwSB">Expiration Date</li>
              <li className="mb6">2023-09-20</li>
              <li className="mb6">2023-09-22</li>
            </ul>
          </div>
        </div>
        <div className="fs20 mb24">
          <p className="fwSB mb12 cPurple">Approvals and Permits to be Obtained</p>
          <ul>
            <li className="mb12 cPurple fwSB">Permit 1</li>
            <li className="mb6">Permit 2</li>
            <li className="mb6">Permit 3</li>
          </ul>
        </div>
      </OfferingDetailCard> */}

      <div className="dFA jcC">
        <FundDetailCard className="shadow-lg">
          <div className="dF fs20 fwSB mb24 ">
            <span className="cPurple">Capital Type:</span>&nbsp;
            <span className="c333">{offeringDetails?.capitalType}</span>
          </div>
          <div className="dF fs20 fwSB mb24 cPurple">
            <span className="cPurple">No. of Shares:</span>&nbsp;
            <span className="c333">{offeringDetails?.sharesCount}</span>
          </div>
          <div className="dF fs20 fwSB mb24 cPurple">
            <span className="cPurple">Funding Goal (OMR):</span>&nbsp;
            <span className="c333">{offeringDetails?.fundingGoal}</span>
          </div>
          <div className="dF fs20 fwSB mb24 cPurple">
            <span className="cPurple">Share price (OMR):</span>&nbsp;
            <span className="c333">{offeringDetails?.pricePerShare}</span>
          </div>
          <div className="dF fs20 fwSB mb24 cPurple">
            <span className="cPurple">Estimated IPR:</span>&nbsp;
            <span className="c333">{offeringDetails?.liquidatingPercentage}%</span>
          </div>
        </FundDetailCard>
        <ShareCard className="shadow-lg">
          <div className="dF jcSB">
            <div className="dFA fdC fs24 fwSB">
              <p className="mb12 cPurple">Min Share</p>
              <p className="c333">{offeringDetails?.minCommitmentShares}</p>
            </div>
            <div className="dFA fdC fs24 fwSB">
              <p className="mb12 cPurple">Max Share</p>
              <p className="c333">{offeringDetails?.maxCommitmentShares}</p>
            </div>
          </div>
        </ShareCard>
      </div>
      <InvestersModal scrollable visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          <CModalTitle className="fs24 fwB">Investers</CModalTitle>
        </CModalHeader>
        <CModalBody className="scrollbar-class ">
          <p className="fs20 mb32">
            <span className="fwSB cOrange">0 (OMR)</span>&nbsp; raised of&nbsp;
            <span className="fwSB cOrange">{offeringDetails?.fundingGoal}</span>&nbsp; Fund Goal
          </p>
          <div className="mb8 partitionBorder"></div>
          <p className="mb64">0 Donations</p>
          <div className="mb48 dFA ">
            <ProgressButton className="fs20 dFA fwSB" shape="rounded-pill">
              Check Progress
            </ProgressButton>
            <img src="/icons/share-icon.svg" className="mx-2" />
          </div>
          <p className="fs20 fwSB taC mb24">{/* <u>See Top Investers</u> */}</p>
          {/* {investerModalDetails.map((item, index) => {
            return (
              <CCard key={index} className="dFA fdR mb24 shadow investerCard">
                <div>
                  <img src={item.src} />
                </div>
                <div className="ml24">
                  <p className="fs20 fwSB mb12">{item.title}</p>
                  <p style={{ color: '#D3D3D3' }} className="fs16">
                    {item.month} {item.date} at {item.time}
                  </p>
                </div>
              </CCard>
            )
          })} */}
        </CModalBody>
      </InvestersModal>
      {showInvestModal && (
        <InvestModal onCancel={() => setShowInvestModal(false)} offeringDetails={offeringDetails} />
      )}
    </Wrapper>
  )
}

export default OfferingSDetails
