import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import useAxios from 'axios-hooks'
import { AppContext } from '../../context/app'
import SearchTable from 'src/components/searchTable'
import { ACCOUNT_URL } from 'src/views/utils/urls'

const orderHistoryDetails = [
  {
    id: '649b4b3153a0aefc9c120a2e',
    sender: '6491f77900a615238dcb6e05',
    receiver: '6492fc9ecdd8fed1ff05f591',
    type: 'Credit',
    amount: '5',
    balance: '10,000',
    details: 'this is sample detail',
    date: '2023-06-27T20:48:49.569Z',
    success: true,
  },
  {
    id: '649b4a9653a0aefc9c120a24',
    sender: '6491f77900a615238dcb6e05',
    receiver: '6492fc9ecdd8fed1ff05f591',
    type: 'debit',
    amount: '10',
    balance: '10,000',
    details: 'this is sample detail',
    date: '2023-06-27T20:46:14.034Z',
    success: true,
  },
]

const MyOrderDetails = () => {
  const { setLoader, accountObj } = useContext(AppContext)
  const accountId = localStorage.getItem('accountId')

  console.log({ accountId })

  const [{ data: orderHistoryDetails, loading: orderHistoryLoader }, orderHistory] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/wallet-transactions`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    orderHistory()
  }, [])

  console.log({ orderHistoryDetails })

  useEffect(() => {
    setLoader(orderHistoryLoader)
  }, [orderHistoryLoader])

  const tableConfig = [
    {
      header: 'Date',
      dataCell: (info) => moment.utc(info.date).format('DD-MM-YYYY'),
      width: 20,
    },
    {
      header: 'Amount',
      dataCell: (info) => info.amount,
      width: 25,
    },
    {
      header: 'Type',
      dataCell: (info) => `DEBIT`,
      width: 20,
    },
    {
      header: 'Details',
      dataCell: (info) => `wallet to wallet transaction`,
      width: 30,
    },
    // {
    //   header: 'Balance',
    //   dataCell: (info) => info.balance,
    //   width: 20,
    // },
  ]
  return (
    <div className="mt-5 px-5">
      <h4 style={{ fontSize: '1.5625vw', fontWeight: '600' }} className="font-bold">
        Order History
      </h4>
      <SearchTable
        type={`Order`}
        tableCardHeight={65}
        marginDisable={false}
        disableButton={true}
        disableSearch={true}
        // tableContent={artistList && artistList.data}
        tableContent={orderHistoryDetails}
        tableConfig={tableConfig}
      />
    </div>
  )
}

export default MyOrderDetails
