import React from 'react'
import styled from 'styled-components'
import FundtLogo from '../../assets/fundt-logo.svg'

const TopBar = styled.div`
  padding: 8px 60px;
  background: #f4f4f4;
  border-radius: 0px 0px 50px 50px;
  width: 90%;
  margin-bottom: 80px;
`

const SignupButton = styled.div`
  color: #211f9f;
  background: #fff;
  border-radius: 20px;
  padding: 10px 16px;
  border: 1px solid #211f9f;
`

const LoginTopBar = () => {
  return (
    <TopBar className="dFA jcSB mA">
      <img src={FundtLogo} alt="Fundt Logo" width="216" height="120" />
      <div className="dF">
        <SignupButton className="fs24 fwB">
          <a href="http://registration.fundt.om/" target="_blank" rel="noreferrer">
            SIGN UP
          </a>
        </SignupButton>
      </div>
    </TopBar>
  )
}

export default LoginTopBar
