import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CCard } from '@coreui/react'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { AppContext } from 'src/views/context/app'
import useAxios from 'axios-hooks'

const Wrapper = styled.div`
  padding: 32px 0;
  gap: 24px;
  .c333 {
    color: #333;
  }
  .cPurple {
    color: #5d3fd390;
  }
`
// need to make this card common as it's grtting used at other places as well
const FundDetailCard = styled(CCard)`
  width: 30%;
  border-radius: 10px;
  padding: 24px 48px;
  margin-right: 45px;
  color: #fff;
  border: none;
  background: #fff;
`

const MyWallet = () => {
  const { setLoader } = useContext(AppContext)

  const accountId = localStorage.getItem('accountId')
  const [walletInfo, setWalletInfo] = useState({})

  const [{ data: walletData, loading: walletLoading }, walletDetailsCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/wallet`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    walletDetailsCall()
  }, [])

  useEffect(() => {
    setLoader(walletLoading)
  }, [walletLoading])

  useEffect(() => {
    if (walletData && walletData.success) {
      setWalletInfo(walletData)
    }
  }, [walletData])

  return (
    <Wrapper className="dF jcC fwW">
      <FundDetailCard className="dFA jcC shadow-lg">
        <p className="mb16 cPurple">ACCOUNT NUMBER</p>
        <p className="c333">{walletData?.id}</p>
      </FundDetailCard>
      <FundDetailCard className="dFA jcC shadow-lg">
        <p className="mb16 cPurple">AVAILABLE BALANCE</p>
        <p className="c333">{walletData?.balance}</p>
      </FundDetailCard>
      <FundDetailCard className="dFA jcSB fdR shadow-lg">
        <div>
          <p className="mb16 c333">{walletInfo?.deposits || 0}</p>
          <p className="cPurple">DEPOSITS</p>
        </div>
        <img src="/icons/wallet-deposits-icon.svg" alt="deposits" />
      </FundDetailCard>
      <FundDetailCard className="dFA jcSB fdR shadow-lg">
        <div>
          <p className="mb16 c333">{walletInfo?.withdrawals || 0}</p>
          <p className="cPurple">WITHDRAWALS</p>
        </div>
        <img src="/icons/wallet-withdraw-icon.svg" alt="withdraw" />
      </FundDetailCard>
      <FundDetailCard className="dFA jcSB fdR shadow-lg">
        <div>
          <p className="mb16 c333">{walletInfo?.transactions || 0}</p>
          <p className="cPurple">TRANSACTIONS</p>
        </div>
        <img src="/icons/wallet-transaction-icon.svg" alt="transactions" />
      </FundDetailCard>
      <FundDetailCard className="dFA jcSB fdR shadow-lg">
        <div>
          <p className="mb16 c333">{walletInfo?.equity || 0}</p>
          <p className="cPurple">EQUITY</p>
        </div>
        <img src="/icons/wallet-equity-icon.svg" alt="equity" />
      </FundDetailCard>
      <FundDetailCard className="dFA jcSB fdR shadow-lg">
        <div>
          <p className="mb16 c333">{walletInfo?.loan || 0}</p>
          <p className="cPurple">LOAN</p>
        </div>
        <img src="/icons/wallet-loan-icon.svg" alt="loan" />
      </FundDetailCard>
    </Wrapper>
  )
}

export default MyWallet
