import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { AppContext } from '../../context/app'
import SearchTable from 'src/components/searchTable'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { isEmpty } from 'src/views/utils/helper'
import AddExpenseModal from 'src/components/addExpenseModal/addExpenseModal'

const Expenses = () => {
  const { setLoader } = useContext(AppContext)
  const [expenseDetails, setExpenseDetails] = useState([])
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false)
  const accountId = localStorage.getItem('accountId')

  const [{ data: expenseHistoryDetails, loading: expensesLoading }, expensesHistory] = useAxios(
    {
      url: ACCOUNT_URL + `/${accountId}/expenses`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    expensesHistory()
  }, [])

  useEffect(() => {
    setLoader(expensesLoading)
  }, [expensesLoading])

  useEffect(() => {
    if (!isEmpty(expenseHistoryDetails) && expenseHistoryDetails[0].success) {
      setExpenseDetails(expenseHistoryDetails)
    }
  }, [expenseHistoryDetails])

  const onAddExpense = () => {
    setShowAddExpenseModal(true)
  }

  const tableConfig = [
    {
      header: 'Expense',
      dataCell: (info) => info.name,
      width: 15,
    },
    {
      header: 'Account No.',
      dataCell: (info) => info.bankAccountNumber,
      width: 20,
    },
    {
      header: 'Type',
      dataCell: (info) => info.type,
      width: 15,
    },
    {
      header: 'Bank Name',
      dataCell: (info) => info.bankName,
      width: 15,
    },
    {
      header: 'Benificiary Name',
      dataCell: (info) => info.bankBenificiaryName,
      width: 12,
    },
  ]
  return (
    <div className="mt-5 px-5">
      <div className="w-100 d-flex justify-content-between">
        <h4 style={{ fontSize: '1.5625vw', fontWeight: '600' }} className="font-bold">
          Expenses
        </h4>
      </div>
      <SearchTable
        type={`Transaction`}
        tableCardHeight={65}
        marginDisable={false}
        createPermission={true}
        buttonFunction={onAddExpense}
        buttonName="Add Expense"
        disableSearch={true}
        tableContent={expenseDetails}
        tableConfig={tableConfig}
      />
      {showAddExpenseModal && (
        <AddExpenseModal
          onClose={() => setShowAddExpenseModal(false)}
          onAddExpense={() => {
            expensesHistory()
            setShowAddExpenseModal(false)
          }}
        />
      )}
    </div>
  )
}

export default Expenses
