import React from 'react'
import { CFormInput } from '@coreui/react'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import { css, styled } from 'styled-components'
import { camelToTitleCase } from 'src/views/utils/helper'

const headingStyle = {
  fontSize: '1.1vw',
  fontWeight: '600',
  color: '#5C4C73',
}

const InputsRow = styled.div`
  gap: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d3d3d3;
  & input {
    ${({ width }) =>
      width &&
      css`
        width: calc(${width}% - 24px);
      `}
  }
`

const RowsCard = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #666;
  overflow-y: scroll;
  max-height: 400px;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    margin-right: 12px;
    background-color: #0074d9;
    border-radius: 5px; /* Rounded corners for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3;
  }
`

const AddRow = styled(FaPlusCircle)`
  right: 24px;
`

const RowInput = ({ removeRow, rowIndex, state, updateRowData, fields, width }) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const updatedRowData = [...state]
    updatedRowData[index][name] = value
    updateRowData(updatedRowData)
  }

  return (
    <InputsRow className="dFA fwW pR mb24" width={width}>
      {fields.map((item, index) => {
        return (
          <CFormInput
            key={index}
            type="text"
            name={item}
            value={state[rowIndex].item}
            onChange={(e) => handleInputChange(e, rowIndex)}
            placeholder={camelToTitleCase(item)}
            required
          />
        )
      })}

      {rowIndex > 0 && (
        <FaMinusCircle className="cP pA r0" size={24} onClick={() => removeRow(rowIndex)} />
      )}
    </InputsRow>
  )
}

const DynamicInputRows = ({ containerStyle, state, setState, data }) => {
  const addRow = () => {
    setState([...state, data.initialState])
  }

  const removeRow = (index) => {
    const updatedRowData = [...state]
    updatedRowData.splice(index, 1)
    setState(updatedRowData)
  }

  return (
    <div>
      <h3 className="mb12" style={headingStyle}>
        {data.title}
      </h3>
      <RowsCard className="pR" style={containerStyle}>
        <AddRow className="cP pA mb24" size={24} onClick={addRow} />
        {state.map((row, index) => (
          <>
            {!index && (
              <div className="dF">
                {data.fields.map((item, index) => {
                  return (
                    <h3 className="fwSB mb16" key={index} style={{ width: `${data.fieldsWidth}%` }}>
                      {camelToTitleCase(item)}
                    </h3>
                  )
                })}
              </div>
            )}
            <RowInput
              key={index}
              fields={data.fields}
              width={data.fieldsWidth}
              rowIndex={index}
              state={state}
              updateRowData={setState}
              removeRow={removeRow}
            />
          </>
        ))}
        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      </RowsCard>
    </div>
  )
}

export default DynamicInputRows
