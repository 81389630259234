import React, { useContext, useEffect, useState } from 'react'
import {
  CCard,
  CButton,
  CModalTitle,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CFormFeedback,
} from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import { getImageUrl, isEmpty, raiseToast } from 'src/views/utils/helper'
import { AppContext } from 'src/views/context/app'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import useAxios from 'axios-hooks'

const InvestModal = ({ offeringDetails, onCancel }) => {
  const { setLoader } = useContext(AppContext)
  const navigate = useNavigate()
  const accountId = localStorage.getItem('accountId')

  const [minimumShareValue, setMinimumShareValue] = useState(offeringDetails.minCommitmentShares)
  const [minimumShareAmount, setMinimumShareAmount] = useState(0)
  const [errorData, setErrorData] = useState('')

  const [{ data: createData, loading: createLoading }, createCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/wallet-transactions`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (minimumShareValue && offeringDetails?.pricePerShare) {
      let calculatedAmount = Number(minimumShareValue) * Number(offeringDetails.pricePerShare)
      setMinimumShareAmount(calculatedAmount.toFixed(2))
    }
  }, [minimumShareValue])

  useEffect(() => {
    if (createData && createData.success) {
      onCancel()
      navigate('/all-offerings')
      raiseToast('success', 'Invested Successfully!!')
    }
  }, [createData])

  useEffect(() => {
    setLoader(createLoading)
  }, [createLoading])

  const validateInfo = () => {
    let isFormValid = true

    if (!minimumShareValue) {
      isFormValid = false
      setErrorData(`Value must be minimum of ${offeringDetails.minCommitmentShares}`)
    }

    if (minimumShareValue < offeringDetails.minCommitmentShares) {
      isFormValid = false
      setErrorData(`Value must be minimum of ${offeringDetails.minCommitmentShares}`)
    }
    if (minimumShareValue > offeringDetails.maxCommitmentShares) {
      isFormValid = false
      setErrorData(`Value must be maximum of ${offeringDetails.maxCommitmentShares}`)
    }

    return isFormValid
  }

  const handleCreate = async () => {
    if (!validateInfo()) return

    await createCall({
      data: {
        amount: minimumShareAmount,
        receiver: offeringDetails.account,
      },
    })
  }

  const onMinimumShareChange = (e) => {
    setErrorData('')
    const { value } = e.target
    setMinimumShareValue(Number(value))
  }

  return (
    <CModal size="lg" visible={true} alignment="center">
      <CModalHeader className="px-5" closeButton={false}>
        <p style={{ fontSize: '1.2vw', fontWeight: '600' }}>{'Invest Money'} </p>
      </CModalHeader>
      <CModalBody style={{ height: '30vh' }} className="px-5 ">
        <div className="mb32">
          <p className="mb8" style={{ fontSize: '1.1vw', fontWeight: '600' }}>
            Minimum No. of shares
          </p>
          <CFormInput
            type="number"
            value={minimumShareValue}
            placeholder="Enter minimum No. of shares"
            onChange={onMinimumShareChange}
            invalid={errorData.length > 0}
          />
          {errorData && (
            <CFormFeedback style={{ color: 'red', fontSize: '14px' }}>{errorData}</CFormFeedback>
          )}
        </div>
        <div>
          <p className="mb8" style={{ fontSize: '1.1vw', fontWeight: '600' }}>
            {'Amount (OMR)'}
          </p>
          <CFormInput value={minimumShareAmount} readOnly disabled={true} />
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton className="text-white mx-3 px-4" color="info" onClick={onCancel}>
          Cancel
        </CButton>
        <CButton className="text-white mx-3 px-4" color="info" onClick={handleCreate}>
          Confirm
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default InvestModal
