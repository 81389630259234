import React, { useContext, useEffect, useState } from 'react'
import { CButton, CCard, CCol, CRow } from '@coreui/react'
import { styled } from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty } from 'src/views/utils/helper'
import { AppContext } from 'src/views/context/app'
import { ACCOUNT_URL, ALL_OFFERING } from 'src/views/utils/urls'
import useAxios from 'axios-hooks'
import moment from 'moment'

const Wrapper = styled.div`
  padding: 32px 24px;
  .mb6 {
    margin-bottom: 6px;
  }
  .ml24 {
    margin-left: 24px;
  }
  .cPurple {
    color: #5c4c73;
  }
  .w70 {
    width: 70%;
  }
`

const AllOffering = () => {
  const navigate = useNavigate()
  const [offeringDetails, setOfferingDetails] = useState([])
  const { setLoader } = useContext(AppContext)
  const accountId = localStorage.getItem('accountId')

  const [{ data: offeringData, loading: offeringLoading }, offeringCall] = useAxios(
    {
      url: `${ALL_OFFERING}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    offeringCall()
  }, [])

  useEffect(() => {
    setLoader(offeringLoading)
  }, [offeringLoading])

  useEffect(() => {
    if (offeringData && !isEmpty(offeringData)) {
      setOfferingDetails(offeringData)
    }
  }, [offeringData])

  return (
    <Wrapper>
      <div className="dFA jcSB mb40">
        <p className="fs24 fwSB cPurple mb6">Recent Opening</p>
      </div>
      <CRow xs={{ cols: 3 }}>
        {offeringDetails.map((info, idx) => (
          <CCol className="pb-4" key={idx}>
            <CCard
              className="px-4 py-5 dF fdC jcSB"
              style={{ borderRadius: '20px', width: '19.635vw', aspectRatio: 377 / 342 }}
            >
              <div className="mt-2">
                <p className="line-clamp-2" style={{ fontSize: '1.25vw', fontWeight: '550' }}>
                  {info?.owner}
                </p>
                <p
                  className="line-clamp-3 mt-3"
                  style={{ fontSize: '0.78125vw', color: '#00000050' }}
                >
                  {info?.shortDescription}
                </p>
              </div>
              <div className="dFA jcSB">
                <div className="dFA fdC">
                  <p style={{ fontSize: '0.833vw', fontWeight: '550' }}>100.3 (OMR)</p>
                  <p style={{ fontSize: '0.729vw', color: '#00000050' }}>Raised</p>
                </div>
                <div className="dFA fdC">
                  <p style={{ fontSize: '0.833vw', fontWeight: '550' }}>60</p>
                  <p style={{ fontSize: '0.729vw', color: '#00000050' }}>Investors</p>
                </div>
                <div className="dFA fdC">
                  <p style={{ fontSize: '0.833vw', fontWeight: '550' }}>
                    {Number(info.pricePerShare) * Number(info.minCommitmentShares)} (OMR)
                  </p>
                  <p style={{ fontSize: '0.729vw', color: '#00000050' }}>Min. Investment</p>
                </div>
              </div>
              <div className="w-100 dFA jcFE">
                <CButton
                  style={{
                    background: '#02C19F',
                    border: '1px solid #02C19F',
                    fontSize: '0.833vw',
                  }}
                  className="text-white"
                  shape="rounded-pill"
                  onClick={() => navigate(`/offering-details/${info.id}`)}
                >
                  Invest Now
                </CButton>
              </div>
            </CCard>
          </CCol>
        ))}

        {/* <CCol>
          <CCard></CCard>
        </CCol>
        <CCol>
          <CCard></CCard>
        </CCol>
        <CCol>
          <CCard></CCard>
        </CCol> */}
      </CRow>
    </Wrapper>
  )
}

export default AllOffering
