import React, { useContext } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { CContainer } from '@coreui/react'
import Dashboard from 'src/views/dashboard/Dashboard'
import Offering from 'src/views/pages/createOffering/createOffering'
import { AppContext } from 'src/views/context/app'
import { GlobalContext } from 'src/views/context/GlobalState'
import MyOffering from 'src/views/pages/myOfferings/myOfferings'
import OfferingDetails from 'src/views/pages/offeringDetails/offeringDetails'
import MyWallet from 'src/views/pages/myWallet/myWallet'
import MyTransactionHistory from 'src/views/pages/myTransactionHistory/myTransactionHistory'
import MyOrderDetails from 'src/views/pages/myOrderDetails/myOrderDetails'
import DepositMoney from 'src/views/pages/depositMoney/depositMoney'
import WithdrawMoney from 'src/views/pages/withdrawMoney/withdrawMoney'
import CreateOffering from 'src/views/pages/createOffering/createOffering'
import AllOffering from 'src/views/pages/allOffering/allOffering'
import NewOffering from 'src/views/pages/newOffering/newOffering'
import Expenses from 'src/views/pages/expenses/expenses'
import { ROUTES } from './PathRoutes'
import EditOffering from 'src/views/pages/createOffering/editOffering'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/offering', name: 'Offering', element: Offering },
  {
    path: '*',
    name: 'Dashboard',
    element: Dashboard,
  },
]

const Routing = () => {
  let accountId = localStorage.getItem('accountId')
  let token = localStorage.getItem('token')
  // debugger
  const { userSessionObj, signedIn, userObj } = useContext(GlobalContext)
  const { accountObj } = useContext(AppContext)
  // debugger

  return (
    <>
      <Routes>
        {accountId && token ? (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />

            <Route path="my-wallet" element={<MyWallet />} />
            <Route path="transaction-history" element={<MyTransactionHistory />} />
            <Route path="order-details" element={<MyOrderDetails />} />
            <Route path="deposit-money" element={<DepositMoney />} />
            <Route path="withdraw-money" element={<WithdrawMoney />} />
            {/* fundraiser */}
            <Route path="create-offering" element={<CreateOffering />} />
            <Route path="new-offering" element={<NewOffering />} />
            <Route path="expenses" element={<Expenses />} />
            <Route path="my-offerings" element={<MyOffering />} />
            <Route path="offering-details/:offeringId" element={<OfferingDetails />} />
            {/* investor */}
            <Route path="/all-offerings" element={<AllOffering />} />
            <Route path="/all-offerings" element={<AllOffering />} />
            <Route path="*" element={<Dashboard />} />
            <Route path={ROUTES.editOffferings} element={<EditOffering />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="login" replace />} />
        )}
      </Routes>
    </>
  )
}

export default React.memo(Routing)
