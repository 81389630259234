import React from 'react'
import { CButton, CFormInput } from '@coreui/react'
const ClipboardCopy = ({ copyText, css }) => {
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {})
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={`position-relative d-flex ${css}`}>
      <CFormInput type="text" value={copyText} readOnly />
      <CButton
        variant="outline"
        color="warning"
        className="position-absolute end-0 hover-text-white"
        onClick={handleCopyClick}
      >
        Copy
      </CButton>
    </div>
  )
}

export default ClipboardCopy
