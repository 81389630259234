import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import DropDownErrorMessage from 'src/components/common/dropDownErrorMessage'
import CustomSelect from 'src/components/customSelect/customSelect'
import TextField from 'src/components/textField/textField'
import { AppContext } from 'src/views/context/app'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { isEmpty, raiseToast } from 'src/views/utils/helper'

const Wrapper = styled.div`
  padding: 60px 108px;
  /* margin-left: auto; */
  width: 75%;
  .w30 {
    width: 30%;
  }
  .mr34 {
    margin-right: 34px;
  }
`

const FieldLabel = styled.div`
  p {
    font-size: 16px;
  }
`

const ButtonWrapper = styled.div`
  color: #35a2d1;
`

const NextButton = styled.button`
  color: #fff;
  background: #35a2d1;
  border-radius: 5px;
  padding: 10px 32px;
  border: none;
`

// const UploadField = styled.div`
//   input {
//     border: 1px solid red;
//   }
//   cursor: pointer;
// `
// const CustomLink = styled(Link)`
//   color: #35a2d1;
//   font-size: 16px;
//   font-weight: bold;
// `

const WithdrawMoney = () => {
  const { setLoader } = useContext(AppContext)
  const [expenseDetails, setExpenseDetails] = useState([])
  const [walletInfo, setWalletInfo] = useState({})

  const accountId = localStorage.getItem('accountId')

  const [{ data: expenseHistoryDetails, loading: expensesLoading }, expensesHistory] = useAxios(
    {
      url: ACCOUNT_URL + `/${accountId}/expenses`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: walletData, loading: walletLoading }, walletDetailsCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/wallet`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: withdrawalData, loading: withdrawalLoading }, withdrawalCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/escrow-transactions`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    expensesHistory()
    walletDetailsCall()
  }, [])

  useEffect(() => {
    setLoader(expensesLoading || walletLoading || withdrawalLoading)
  }, [expensesLoading, walletLoading, withdrawalLoading])

  useEffect(() => {
    if (!isEmpty(expenseHistoryDetails) && expenseHistoryDetails[0].success) {
      setExpenseDetails(
        expenseHistoryDetails.map((info) => ({ ...info, value: info.id, label: info.name })),
      )
    }
  }, [expenseHistoryDetails])

  useEffect(() => {
    if (walletData && walletData.success) {
      setWalletInfo(walletData)
    }
  }, [walletData])

  useEffect(() => {
    if (withdrawalData && withdrawalData.success) {
      raiseToast('success', 'Withdrawal Request placed!')
    }
  }, [withdrawalData])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    // getValues,
  } = useForm()

  const onSubmit = async (data) => {
    // console.log('data', walletInfo.balance, data.amount, data.withdrawal_expense.id)
    if (Number(walletInfo.balance) < Number(data.amount)) {
      raiseToast('error', "You don't have enough balance!")
      return
    }

    await withdrawalCall({
      data: {
        amount: parseFloat(data.amount).toFixed(2),
        type: 'DEBIT',
        transactionScreenshot: '/',
        expense: data.withdrawal_expense.id,
      },
    })
    // console.log("onSubmit", data);
  }

  return (
    <Wrapper>
      <p className="fs36 fwB mb40">WITHDRAW MONEY</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldLabel className=" w100 mb40">
          <p className="fs16 mb16 fwB">
            <u>WITHDRAWAL EXPENSE</u>
          </p>
          <Controller
            name="withdrawal_expense"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CustomSelect placeholder="Select Purpose" {...field} options={expenseDetails} />
              )
            }}
          />
          {errors.withdrawal_expense && (
            <DropDownErrorMessage>Withdrawal Purpose is required</DropDownErrorMessage>
          )}
        </FieldLabel>
        <FieldLabel className="w100 mb40">
          <p className="fs16 mb16 fwB">
            <u>AMOUNT</u>
          </p>
          <TextField
            id="amount"
            label="Amount"
            type="number"
            name="amount"
            // NOTE: this should be coming from API according to the nationality of user in future
            currencyCode="omr"
            errors={errors}
            register={register}
            validationSchema={{
              required: 'Amount is required',
              minLength: {
                value: 1,
                message: 'Please enter a minimum of 2 characters',
              },
            }}
            required
          />
        </FieldLabel>
        {/* <FieldLabel className=" w100 mb40">
          <p className="fs16 mb16 fwB">
            <u>AUTHORIZATION MODE</u>
          </p>
          <Controller
            name="authorization"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder="Authorization Mode"
                  {...field}
                  options={authorizationMode}
                />
              )
            }}
          />
          {errors.authorization && (
            <DropDownErrorMessage>Authorization Mode is required</DropDownErrorMessage>
          )}
        </FieldLabel> */}
        <ButtonWrapper className="dFA jcSB">
          <NextButton type="submit">NEXT STEP</NextButton>
        </ButtonWrapper>
      </form>
    </Wrapper>
  )
}

export default WithdrawMoney
