import React, { useRef, useState } from 'react'
import { CButton, CFormInput, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import { raiseToast } from 'src/views/utils/helper'
import { jsPDF } from 'jspdf'
// import useUploadS3Hook from 'src/s3upload/useUploadS3Hook'
import ClipboardCopy from 'src/components/copyToClipboard'
import exportAsImage from 'src/views/utils/exportAsImage'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'

const ReceiptModal = ({ data, onClose }) => {
  const templateRef = useRef(null)

  const handleGeneratePdf = () => {
    exportAsImage(templateRef.current, `transaction-${data.id}`)
  }

  return (
    <CModal scrollable size="lg" visible={true} ref={templateRef}>
      <CModalHeader
        className="px-5 d-flex flex-column align-items-center justify-content-center position-relative"
        closeButton={false}
      >
        <p style={{ fontSize: '1.2vw', fontWeight: '600' }}>{'LOAD MONEY'} </p>
        <p style={{ fontSize: '14px', color: 'gray' }}>
          {'Send the amount shown to the banking details below:'}
        </p>
        <CIcon
          icon={cilX}
          style={{ width: '24px', height: '24px' }}
          className="position-absolute end-0 top-0 mx-4 my-4 cP"
          onClick={onClose}
        />
      </CModalHeader>
      <CModalBody className="px-5 w-100">
        <div className="w-100">
          <div style={{ textAlign: 'center' }} className="mt-2 mb-2">
            <p style={{ fontSize: '1.1vw' }}>{'Pay Amount (OMR)'} </p>
            <p style={{ fontSize: '1.1vw', fontWeight: '600' }}>
              {parseFloat(data?.amount).toFixed(2)}{' '}
            </p>
          </div>
          <div className="">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'Swift Code'} </p>
            <ClipboardCopy copyText={'INGBNL2A'} />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'Transaction ID'} </p>
            <ClipboardCopy copyText={data?.id} />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'Account Holder'} </p>
            <ClipboardCopy copyText={'Fundt Pvt. Ltd.'} />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'Bank Name'} </p>
            <ClipboardCopy copyText={'Muscat Bank'} />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'IBAN'} </p>
            <ClipboardCopy copyText={'2ADKLJDANA10'} />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'City'} </p>
            <ClipboardCopy copyText={'Muscat'} />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'Country'} </p>
            <ClipboardCopy copyText={'Oman'} />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: '1vw', fontWeight: '600' }}>{'Bank Account Number'} </p>
            <ClipboardCopy copyText={'NL42INGB00201003384'} />
          </div>
          <div className="mt-2">
            <p style={{ fontSize: '14px', color: 'red' }}>
              * Remember to check the numbers carefully include the <b>Transaction ID</b> when
              providing them to your bank. After Funds are sent, we will notify when the funds have
              been Deposited into you Account.
            </p>
          </div>
        </div>
      </CModalBody>
      <CModalFooter className="px-5 d-flex justify-content-center">
        <CButton className="text-white px-4" color="danger" onClick={handleGeneratePdf}>
          Print
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default ReceiptModal
