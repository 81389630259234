import { toast } from 'react-toastify'
import { get, uniqBy } from 'lodash'

export const getImageUrl = (s3Key) => {
  return `https://fundt1.s3.amazonaws.com/${s3Key}`
}

export const formatFileName = (fileName) => {
  const extension = fileName.split('.').pop()
  const extRemoved = fileName.replace(`.${extension}`, '')
  return `${extRemoved.replace(/[^A-Z0-9]+/gi, '_')}${Date.now()}.${extension}`.toLowerCase()
}

export const masterAndChildIntersection = (masterData = [], childData = []) => {
  return uniqBy(masterData, (obj) => obj?._id).filter((masterObj) =>
    childData.find((obj) => masterObj?._id === obj?._id),
  )
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function raiseToast(type, message) {
  if (type === 'success') {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'error') {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'warn') {
    toast.warn(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'info') {
    toast.info(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const checkPermissions = (permissions, permToCheck) => {
  let isAllowed = false
  if (permToCheck) {
    if (typeof permToCheck === 'string') {
      isAllowed |= get(permissions, permToCheck)
    } else {
      permToCheck.forEach((perm) => {
        isAllowed |= get(permissions, perm)
      })
    }
  }
  return isAllowed
}

export const checkModuleAllowed = (moduleName, accountPermissions) => {
  if (accountPermissions && moduleName in accountPermissions) {
    return accountPermissions[moduleName]
  }
  return true
}

export function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?',
    'i',
  ) // fragment locator
  console.log('!!pattern.test(str)', !!pattern.test(str))
  return !!pattern.test(str)
}

export const createSlug = (name = '') => {
  return name.toLowerCase().replace(/[^A-Z0-9]+/gi, '-')
}

export const getImage = (cfDomainUrl, s3Key) => {
  return cfDomainUrl + '/' + s3Key
}

export const camelToTitleCase = (item) => {
  const result = item.replace(/([A-Z])/g, ' $1')
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}
