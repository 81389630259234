import { CButton, CCol, CFormInput, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import useAxios from 'axios-hooks'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DynamicInputRows from 'src/components/dynamicRowsInput'
import useUploadS3Hook from 'src/s3upload/useUploadS3Hook'
import { AppContext } from 'src/views/context/app'
import { isEmpty, raiseToast } from 'src/views/utils/helper'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { styled } from 'styled-components'

// we should keep this in common so that this can be used in every form.
const headingStyle = {
  fontSize: '1.1vw',
  fontWeight: '600',
  color: '#5C4C73',
}

const Wrapper = styled.div`
  padding: 32px 0;
  .mb6 {
    margin-bottom: 6px;
  }
`

const CancelButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #fff;
    border: 2px solid #233e5f;
  }
`

const NextButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  background: #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #233e5f90;
    border: 2px solid #233e5f;
  }
`

const rawMaterialInfo = {
  title: 'Raw Material Data',
  fieldsWidth: '24',
  fields: ['itemName', 'requiredUnits', 'costPerUnit', 'suppliers'],
  initialState: { itemName: '', requiredUnits: '', costPerUnit: '', suppliers: '' },
}

const NewOffering = () => {
  const navigate = useNavigate()
  const { handleUploadFiles } = useUploadS3Hook()

  const [selectOwner, setSelectOwner] = useState('')
  const [contactPoint, setContactPoint] = useState('')
  const [offeringName, setOfferingName] = useState('')
  const [estimateEPR, setEstimateEPR] = useState('')
  const [openDate, setOpenDater] = useState('')
  const [closeDate, setCloseDate] = useState('')
  const [capitalType, setCapitalType] = useState('EQUITY')
  const [creditScore, setCreditScore] = useState('')
  const [numberShares, setNumberShares] = useState(0)
  const [pricePerShare, setPricePerShare] = useState(0)
  const [fundingGoal, setFundingGoal] = useState(0)
  const [maxShare, setMaxShare] = useState(0)
  const [minShare, setMinShare] = useState(0)
  const [shortDesc, setShortDesc] = useState('')
  const [longDesc, setLongDesc] = useState('')
  const [socialLink, setSocialLink] = useState('')
  const [imagePaths, setImagePaths] = useState('')
  const accountId = localStorage.getItem('accountId')

  const [rawMaterialData, setRawMaterialData] = useState([
    { itemName: '', requiredUnits: '', costPerUnit: '', suppliers: '' },
  ])

  const { setLoader } = useContext(AppContext)

  const [{ data: createData, loading: createLoading }, createCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/offerings`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(createLoading)
  }, [createLoading])

  useEffect(() => {
    if (createData && createData.success) {
      raiseToast('success', 'Offering Created Succesfully!!')
      navigate('/my-offerings')
    }
  }, [createData])
  // const [selectOwner, setSelectOwner] = useState('')

  const handleSave = async () => {
    if (!isEmpty(imagePaths)) {
      let patchPayload = {
        owner: selectOwner,
        contactPoint: contactPoint,
        name: offeringName,
        openDate: openDate,
        closeDate: closeDate,
        capitalType: capitalType,
        creditScore: creditScore,
        sharesCount: numberShares,
        pricePerShare: pricePerShare,
        fundingGoal: fundingGoal,
        liquidatingPercentage: estimateEPR,
        maxCommitmentShares: maxShare,
        minCommitmentShares: minShare,
        shortDescription: shortDesc,
        longDescription: longDesc,
        imagePaths: [imagePaths],
        socialUrls: [socialLink],
      }

      await createCall({ data: patchPayload })
    } else {
      raiseToast('error', 'Please Upload an Image!')
    }
  }

  const handleFileChange = async (event) => {
    let fileObj = event.target.files
    let uploadStatus = await handleUploadFiles([fileObj])

    if (uploadStatus.success) {
      setImagePaths(uploadStatus.data[0].s3Key)
    }
  }
  return (
    <Wrapper>
      <div>
        <p className="fs24 fwSB mx-3 mb24">Basic Details</p>
        <div className="mx-3">
          <CRow>
            <CCol>
              <p className="mb6" style={headingStyle}>
                SELECT OWNER*
              </p>
              <CFormInput
                type="text"
                value={selectOwner}
                onChange={(e) => setSelectOwner(e.target.value)}
              />
            </CCol>

            <CCol>
              <p className="mb6" style={headingStyle}>
                CONTACT POINT
              </p>
              <CFormInput
                type="text"
                value={contactPoint}
                onChange={(e) => setContactPoint(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                OFFERING NAME*
              </p>
              <CFormInput
                type="text"
                value={offeringName}
                onChange={(e) => setOfferingName(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                ESTIMATES EPR %*
              </p>
              <CFormInput
                type="text"
                value={estimateEPR}
                onChange={(e) => setEstimateEPR(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAMPAIGN OPEN DATE
              </p>
              <CFormInput
                type="date"
                value={openDate}
                onChange={(e) => setOpenDater(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAMPAIGN CLOSE DATE
              </p>
              <CFormInput
                type="date"
                value={closeDate}
                onChange={(e) => setCloseDate(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAPITAL TYPE *
              </p>
              <CFormSelect value={capitalType} onChange={(e) => setCapitalType(e.target.value)}>
                <option value={'EQUITY'}>EQUITY</option>
                <option value={'DEBT'}>DEBT</option>
              </CFormSelect>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CREDIT SCORE
              </p>
              <CFormInput value={creditScore} onChange={(e) => setCreditScore(e.target.value)} />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                NUMBER OF SHARES*
              </p>
              <CFormInput
                type="number"
                value={numberShares}
                onChange={(e) => setNumberShares(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                PRICE PER SHARE (OMR)
              </p>
              <CFormInput
                type="number"
                value={pricePerShare}
                onChange={(e) => setPricePerShare(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                FUNDING GOAL (OMR)
              </p>
              <CFormInput
                type="number"
                value={fundingGoal}
                onChange={(e) => setFundingGoal(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                MAX COMMITMENT,SHARES
              </p>
              <CFormInput
                type="number"
                value={maxShare}
                onChange={(e) => setMaxShare(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                MIN COMMITMENT,SHARES
              </p>
              <CFormInput
                type="number"
                value={minShare}
                onChange={(e) => setMinShare(e.target.value)}
              />
            </CCol>
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Social Link*
              </p>
              <CFormInput
                type="text"
                value={socialLink}
                onChange={(e) => setSocialLink(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Short Description
              </p>
              <CFormTextarea
                type="text"
                rows={4}
                value={shortDesc}
                onChange={(e) => setShortDesc(e.target.value)}
              />
            </CCol>
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Long Description
              </p>
              <CFormTextarea
                type="text"
                rows={4}
                value={longDesc}
                onChange={(e) => setLongDesc(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Upload DOCUMENTS*
              </p>
              <CFormInput
                id="formFile"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                onChange={(e) => handleFileChange(e)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Upload Image*
              </p>
              <CFormInput
                id="formFile"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                onChange={(e) => handleFileChange(e)}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <DynamicInputRows
                state={rawMaterialData}
                setState={setRawMaterialData}
                data={rawMaterialInfo}
              />
            </CCol>
          </CRow>
        </div>
        {/* <p className="mx-3 my-3" style={{ fontSize: '24px', fontWeight: '600' }}>
            OFFERING DESCRIPTION
          </p> */}
        <div className="dF jcFE mx-3 mt-5">
          <CancelButton
            className="fs16 fwSB baW mx-5 text-black"
            variant="outline"
            shape="rounded-pill"
            onClick={() => navigate('/my-offerings')}
          >
            CANCEL
          </CancelButton>
          <NextButton onClick={handleSave} className="fs24 fwSB text-white" shape="rounded-pill">
            SAVE
          </NextButton>
        </div>
      </div>
    </Wrapper>
  )
}

export default NewOffering
