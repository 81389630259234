import { CButton, CCol, CFormInput, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import useAxios from 'axios-hooks'
import React, { useContext, useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import useUploadS3Hook from 'src/s3upload/useUploadS3Hook'
import { AppContext } from 'src/views/context/app'
import { isEmpty, raiseToast } from 'src/views/utils/helper'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { styled } from 'styled-components'

const headingStyle = {
  fontSize: '1.1vw',
  fontWeight: '600',
  color: '#5C4C73',
}

const Wrapper = styled.div`
  padding: 32px 0;
  .mb6 {
    margin-bottom: 6px;
  }
`

const CancelButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #fff;
    border: 2px solid #233e5f;
  }
`

const NextButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  background: #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #233e5f90;
    border: 2px solid #233e5f;
  }
`

const EditOffering = () => {
  const state = useLocation()?.state
  const record = state?.record
  console.log('records data', record)
  const navigate = useNavigate()
  const { handleUploadFiles } = useUploadS3Hook()

  const [selectOwner, setSelectOwner] = useState(record?.owner ? record?.owner : '')
  const [contactPoint, setContactPoint] = useState(record?.contactPoint ? record?.contactPoint : '')
  const [offeringName, setOfferingName] = useState(record?.name ? record?.name : '')
  const [estimateEPR, setEstimateEPR] = useState(record?.liquidatingPercentage)
  const [openDate, setOpenDater] = useState(record?.closeDate ? record?.closeDate : '')
  const [closeDate, setCloseDate] = useState(record?.closeDate ? record?.closeDate : '')
  const [capitalType, setCapitalType] = useState('EQUITY')
  const [creditScore, setCreditScore] = useState(record?.creditScore)
  const [numberShares, setNumberShares] = useState(record?.sharesCount ? record?.sharesCount : 0)
  const [pricePerShare, setPricePerShare] = useState(record?.fundingGoal ? record?.fundingGoal : 0)
  const [fundingGoal, setFundingGoal] = useState(record?.fundingGoal)
  const [maxShare, setMaxShare] = useState(
    record?.maxCommitmentShares ? record?.maxCommitmentShares : 0,
  )
  const [minShare, setMinShare] = useState(
    record?.maxCommitmentShares ? record?.maxCommitmentShares : 0,
  )
  const [shortDesc, setShortDesc] = useState(
    record?.shortDescription ? record?.shortDescription : '',
  )
  const [longDesc, setLongDesc] = useState(record?.longDescription ? record?.longDescription : '')
  const [socialLink, setSocialLink] = useState(record?.socialUrls[0])
  const [imagePaths, setImagePaths] = useState(record?.imagePaths[0])
  const accountId = localStorage.getItem('accountId')
  const { setLoader } = useContext(AppContext)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const handleFileChange = async (event) => {
    const files = event.target.files
    let newUploadedFiles = [...uploadedFiles]

    for (let i = 0; i < files.length; i++) {
      const uploadStatus = await uploadedFiles(files[i])

      if (uploadStatus.success) {
        newUploadedFiles.push({
          file: files[i],
          s3Key: uploadStatus.data[0].s3Key,
        })
      }
    }

    setUploadedFiles(newUploadedFiles)
  }

  const handleRemoveFile = (index) => {
    const newUploadedFiles = [...uploadedFiles]
    newUploadedFiles.splice(index, 1)
    setUploadedFiles(newUploadedFiles)
  }
  const [{ data: createData, loading: createLoading }, createCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/offerings`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(createLoading)
  }, [createLoading])

  useEffect(() => {
    if (createData && createData.success) {
      raiseToast('success', 'Offering Created Succesfully!!')
      navigate('/my-offerings')
    }
  }, [createData])
  // const [selectOwner, setSelectOwner] = useState('')

  const handleSave = async () => {
    const fields = [
      { name: 'Owner', value: selectOwner, setter: setSelectOwner },
      { name: 'Contact Point', value: contactPoint, setter: setContactPoint },
      { name: 'Offering Name', value: offeringName, setter: setOfferingName },
      { name: 'Open Date', value: openDate, setter: setOpenDater },
      { name: 'Close Date', value: closeDate, setter: setCloseDate },
      { name: 'Capital Type', value: capitalType, setter: setCapitalType },
      { name: 'Credit Score', value: creditScore, setter: setCreditScore },
      { name: 'Number of Shares', value: numberShares, setter: setNumberShares },
      { name: 'Price Per Share', value: pricePerShare, setter: setPricePerShare },
      { name: 'Funding Goal', value: fundingGoal, setter: setFundingGoal },
      { name: 'Estimate EPR', value: estimateEPR, setter: setEstimateEPR },
      { name: 'Max Share', value: maxShare, setter: setMaxShare },
      { name: 'Min Share', value: minShare, setter: setMinShare },
      { name: 'Short Description', value: shortDesc, setter: setShortDesc },
      { name: 'Long Description', value: longDesc, setter: setLongDesc },
      { name: 'Social Link', value: socialLink, setter: setSocialLink },
    ]
    let firstEmptyField = fields.find((field) => !field.value)
    if (firstEmptyField) {
      raiseToast('error', `${firstEmptyField.name} is required!`)
      firstEmptyField.setter('')
      return
    }
    let patchPayload = {
      owner: selectOwner,
      contactPoint: contactPoint,
      name: offeringName,
      openDate: openDate,
      closeDate: closeDate,
      capitalType: capitalType,
      creditScore: creditScore,
      sharesCount: numberShares,
      pricePerShare: pricePerShare,
      fundingGoal: fundingGoal,
      liquidatingPercentage: estimateEPR,
      maxCommitmentShares: maxShare,
      minCommitmentShares: minShare,
      shortDescription: shortDesc,
      longDescription: longDesc,
      imagePaths: [imagePaths],
      socialUrls: [socialLink],
    }
    await createCall({ data: patchPayload })
  }

  return (
    <Wrapper>
      <div>
        <p className="fs36 text-center fwSB mx-3 mb24">Edit Offering</p>
        <div className="mx-3">
          <CRow>
            <CCol>
              <p className="mb6" style={headingStyle}>
                SELECT OWNER*
              </p>
              <CFormInput
                type="text"
                value={selectOwner}
                onChange={(e) => setSelectOwner(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CONTACT POINT
              </p>
              <CFormInput
                type="text"
                value={contactPoint}
                onChange={(e) => setContactPoint(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                OFFERING NAME*
              </p>
              <CFormInput
                type="text"
                value={offeringName}
                onChange={(e) => setOfferingName(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                ESTIMATES EPR %*
              </p>
              <CFormInput
                type="text"
                value={estimateEPR}
                onChange={(e) => setEstimateEPR(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAMPAIGN OPEN DATE
              </p>
              <CFormInput
                type="date"
                value={openDate}
                onChange={(e) => setOpenDater(e.target.value)}
              />
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAMPAIGN CLOSE DATE *
              </p>
              <CFormInput
                type="date"
                value={closeDate}
                onChange={(e) => setCloseDate(e.target.value)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAPITAL TYPE *
              </p>
              <CFormSelect value={capitalType} onChange={(e) => setCapitalType(e.target.value)}>
                <option value={'EQUITY'}>EQUITY</option>
                <option value={'DEBT'}>DEBT</option>
              </CFormSelect>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CREDIT SCORE
              </p>
              <CFormInput value={creditScore} onChange={(e) => setCreditScore(e.target.value)} />
            </CCol>
          </CRow>
          {capitalType === 'EQUITY' ? (
            <>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    NUMBER OF SHARES*
                  </p>
                  <CFormInput
                    type="number"
                    value={numberShares}
                    onChange={(e) => setNumberShares(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    PRICE PER SHARE (OMR)
                  </p>
                  <CFormInput
                    type="number"
                    value={pricePerShare}
                    onChange={(e) => setPricePerShare(e.target.value)}
                  />
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    FUNDING GOAL (OMR)
                  </p>
                  <CFormInput
                    type="number"
                    value={fundingGoal}
                    onChange={(e) => setFundingGoal(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    MAX COMMITMENT,SHARES
                  </p>
                  <CFormInput
                    type="number"
                    value={maxShare}
                    onChange={(e) => setMaxShare(e.target.value)}
                  />
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    MIN COMMITMENT,SHARES
                  </p>
                  <CFormInput
                    type="number"
                    value={minShare}
                    onChange={(e) => setMinShare(e.target.value)}
                  />
                </CCol>
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    Social Link*
                  </p>
                  <CFormInput
                    type="text"
                    value={socialLink}
                    onChange={(e) => setSocialLink(e.target.value)}
                  />
                </CCol>
              </CRow>
            </>
          ) : (
            <>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    Repayment Period*
                  </p>
                  <CFormInput
                    type="number"
                    value={numberShares}
                    onChange={(e) => setNumberShares(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    Repayment Period and Type
                  </p>
                  <CFormSelect value={capitalType} onChange={(e) => setCapitalType(e.target.value)}>
                    <option value={'nvoice Financing'}>nvoice Financing</option>
                    <option value={'Cashflow Financing'}>Cashflow Financing</option>
                    <option value={'Working Capital Financing'}>Working Capital Financing</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    FUNDING GOAL (OMR)
                  </p>
                  <CFormInput
                    type="number"
                    value={fundingGoal}
                    onChange={(e) => setFundingGoal(e.target.value)}
                  />
                </CCol>
                <CCol>
                  <p className="mb6" style={headingStyle}>
                    Max. Loan Ticket
                  </p>
                  <CFormInput
                    type="number"
                    value={maxShare}
                    onChange={(e) => setMaxShare(e.target.value)}
                  />
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    Min. Loan Ticket
                  </p>
                  <CFormInput
                    type="number"
                    value={minShare}
                    onChange={(e) => setMinShare(e.target.value)}
                  />
                </CCol>
                <CCol lg="6">
                  <p className="mb6" style={headingStyle}>
                    Social Link*
                  </p>
                  <CFormInput
                    type="text"
                    value={socialLink}
                    onChange={(e) => setSocialLink(e.target.value)}
                  />
                </CCol>
              </CRow>
            </>
          )}

          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Short Description
              </p>
              <CFormTextarea
                type="text"
                rows={4}
                value={shortDesc}
                onChange={(e) => setShortDesc(e.target.value)}
              />
            </CCol>
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Long Description
              </p>
              <CFormTextarea
                type="text"
                rows={4}
                value={longDesc}
                onChange={(e) => setLongDesc(e.target.value)}
              />
            </CCol>
          </CRow>
          <CCol>
            <p className="mb6" style={headingStyle}>
              DOCUMENT TYPE *
            </p>
            <CFormSelect value={capitalType} onChange={(e) => setCapitalType(e.target.value)}>
              <option value={'Company Document'}>Company Document</option>
              <option value={'Pitch Deck'}>Pitch Deck</option>
              <option value={'Feasibility Study / Report'}>Feasibility Study / Report</option>
            </CFormSelect>
          </CCol>
          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Upload Documents
              </p>
              <CFormInput
                id="formFile1"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                onChange={(e) => handleFileChange(e)}
              />
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                Upload Video
              </p>
              <CFormInput
                id="formFile2"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                onChange={(e) => handleFileChange(e)}
              />
            </CCol>
          </CRow>
          <div>
            <CRow className="my-4">
              <CCol lg="6">
                <p className="mb6" style={headingStyle}>
                  Upload Documents
                </p>
                <input
                  id="formFile1"
                  accept="image/png, image/jpg, image/jpeg"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  multiple // Allow multiple file selection
                />
              </CCol>
            </CRow>
            <div>
              {uploadedFiles.map((uploadedFile, index) => (
                <div key={index} className="mb-2">
                  <span>{uploadedFile.file.name}</span>
                  <button onClick={() => handleRemoveFile(index)}>Remove</button>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <p className="mx-3 my-3" style={{ fontSize: '24px', fontWeight: '600' }}>
            OFFERING DESCRIPTION
          </p> */}
        <div className="dF jcFE mx-3 mt-5">
          <CancelButton
            className="fs16 fwSB baW mx-5 text-black"
            variant="outline"
            shape="rounded-pill"
            onClick={() => navigate('/my-offerings')}
          >
            CANCEL
          </CancelButton>
          <NextButton onClick={handleSave} className="fs24 fwSB text-white" shape="rounded-pill">
            SAVE
          </NextButton>
        </div>
      </div>
    </Wrapper>
  )
}

export default EditOffering
