import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
  CFormText,
} from '@coreui/react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const SearchTable = ({
  type,
  tableCardHeight,
  isAssetDashboard,
  marginDisable,
  titleText,
  disableButton,
  buttonName,
  buttonFunction,
  disableSearch,
  searchTerm,
  tableContent,
  tableConfig,
  createPermission,
  paginationEnable = false,
  handleNextPage,
  disabledButtonTitle = '',
  hashWidth = 5,
  textAlign = '',
  totalTableContent = 0,
  searchCallback = null,
  tableHover = false,
  tableRowCursor = 'default',
}) => {
  const [filterTableContent, setFilterTableContent] = useState(tableContent)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (isEmpty(tableContent)) return setFilterTableContent([])
    setFilterTableContent(tableContent)
    // if (tableContent[0].hasOwnProperty(`status`)) {
    //   setFilterTableContent(
    //     tableContent.sort((ele1, ele2) => Number(ele2.status) - Number(ele1.status)),
    //   )
    // } else {
    //   setFilterTableContent(tableContent)
    // }
  }, [tableContent])

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    setSearchText(value)
    if (!searchCallback) {
      setFilterTableContent(
        tableContent.filter((data) => data[`${searchTerm}`].toLowerCase().search(value) !== -1),
      )
    }
  }

  const dynamicSearch = (event) => {
    if (searchCallback && event.key === 'Enter') {
      searchCallback(event.target.value)
    }
  }

  const handleClearSearch = () => {
    setSearchText('')
    searchCallback('')
  }

  return (
    <CRow className={marginDisable ? '' : 'mt-2'}>
      <CCard style={{ minHeight: `${tableCardHeight}vh` }}>
        <CRow className={`${!marginDisable && 'mt-3'} mb-2 align-items-center`}>
          {titleText && <CFormLabel className="mt-1">{titleText}</CFormLabel>}
          <CCol>
            {!isAssetDashboard && !disableSearch && (
              <div className="d-md-flex align-items-center position-relative">
                <CFormInput
                  type="text"
                  style={{ width: '18rem' }}
                  placeholder="Search..."
                  size="sm"
                  value={searchText}
                  onChange={(e) => handleSearch(e)}
                  onKeyPress={(e) => dynamicSearch(e)}
                />
                {searchCallback && searchText && (
                  <FontAwesomeIcon
                    className="position-absolute"
                    style={{ marginRight: '1.3rem', cursor: 'pointer', left: '17rem' }}
                    icon={faTimes}
                    title="clear"
                    onClick={handleClearSearch}
                  />
                )}
              </div>
            )}
            {totalTableContent ? (
              <CFormText className="text-start text-black">
                {`Showing ${tableContent.length} of ${totalTableContent}`}
              </CFormText>
            ) : null}
          </CCol>
          <CCol>
            {!disableButton && (
              <div className="px-2 d-md-flex justify-content-md-end">
                <CButton
                  className="text-white"
                  color="info"
                  onClick={buttonFunction}
                  disabled={!createPermission}
                  style={{ pointerEvents: 'auto' }}
                  title={disabledButtonTitle}
                >
                  {buttonName}
                </CButton>
              </div>
            )}
            {isAssetDashboard && (
              <div className="px-2 d-md-flex justify-content-md-end align-items-center position-relative">
                <CFormInput
                  type="text"
                  style={{ width: '20.5rem' }}
                  placeholder="Search..."
                  size="sm"
                  value={searchText}
                  onChange={(e) => handleSearch(e)}
                  onKeyPress={(e) => dynamicSearch(e)}
                />
                {searchCallback && searchText && (
                  <FontAwesomeIcon
                    className="position-absolute"
                    style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                    icon={faTimes}
                    title="clear"
                    onClick={handleClearSearch}
                  />
                )}
              </div>
            )}
          </CCol>
        </CRow>
        <div
          style={{ maxHeight: `${tableCardHeight - 5}vh` }}
          id="scrollableDiv"
          className="mb-2 tableFixHead"
        >
          <InfiniteScroll
            dataLength={tableContent ? tableContent.length : 0} //This is important field to render the next data
            next={handleNextPage}
            hasMore={paginationEnable}
            style={{ overflowX: 'hidden' }}
            loader={<p>Loading...</p>}
            className="scrollbar-class"
            height={`${tableCardHeight - 5}vh`}
            scrollableTarget="scrollableDiv"
          >
            <CTable className="border" hover={tableHover}>
              <CTableHead className="">
                <CTableRow>
                  <CTableHeaderCell scope="col" style={{ width: `${hashWidth}%` }}>
                    #
                  </CTableHeaderCell>
                  {tableConfig.map((info, idx) => (
                    <CTableHeaderCell
                      scope="col"
                      key={idx}
                      align="middle"
                      style={{
                        height: '100%',
                        width: `${info.width}%`,
                        textAlign: `${textAlign}`,
                      }}
                    >
                      {info.header}
                    </CTableHeaderCell>
                  ))}
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {!isEmpty(tableContent) &&
                  filterTableContent.map((info, index) => (
                    <CTableRow key={index} style={{ cursor: tableRowCursor }}>
                      <CTableDataCell align="middle" style={{ width: `${hashWidth}%` }}>
                        {index + 1}
                      </CTableDataCell>
                      {tableConfig.map((ele, idx) => {
                        return (
                          <CTableDataCell
                            key={idx}
                            align="middle"
                            style={{
                              height: 'auto',
                              width: `${ele.width}%`,
                              textAlign: `${textAlign}`,
                            }}
                            className=""
                          >
                            {ele.dataCell(info, index)}
                          </CTableDataCell>
                        )
                      })}
                    </CTableRow>
                  ))}
                {isEmpty(filterTableContent) && (
                  <CTableRow>
                    <CTableDataCell
                      colSpan={`${tableConfig.length + 1}`}
                      style={{ textAlign: 'center' }}
                    >
                      <p>{`No ${type} Data Available`}</p>
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
          </InfiniteScroll>
        </div>
      </CCard>
    </CRow>
  )
}

export default SearchTable
