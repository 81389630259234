import { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import axios from 'axios'
import { SIGNED_URL } from 'src/views/utils/urls'
import { formatFileName } from 'src/views/utils/helper'
import { AppContext } from 'src/views/context/app'

const useUploadS3Hook = () => {
  const accountId = localStorage.getItem('accountId')
  const { setLoader } = useContext(AppContext)
  const [{ loading: signedURLLoading }, signedURLCall] = useAxios(
    {
      url: `${SIGNED_URL}/${accountId}`,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(signedURLLoading)
  }, [signedURLLoading])

  const handleUploadFiles = async (uplaodFiles) => {
    let tempFileName = []
    let dataArr = []
    let successCount = 0
    let responseObj = {
      success: false,
      message: '',
    }

    for (let index = 0; index < uplaodFiles.length; index++) {
      const file = uplaodFiles[index][0]
      const formatedName = formatFileName(file.name)
      tempFileName.push(formatedName)

      let signedRes = await signedURLCall({
        data: {
          fileName: formatedName,
          fileType: file.type,
        },
      })

      let signedData = signedRes?.data

      if (signedData?.success) {
        const AWSUploadResponse = await axios.put(signedData?.signedUrl, file, {
          transformRequest: (data, headers) => {
            delete headers.Authorization
            return data
          },
        })

        if (AWSUploadResponse.status === 200) {
          successCount++
          dataArr.push({ fileName: formatedName, s3Key: signedData.key })
        }
      }
    }

    if (successCount === uplaodFiles.length) {
      responseObj = {
        success: true,
        message: 'Uploaded Succesfully!!',
        data: dataArr,
      }
    } else {
      responseObj = {
        success: false,
        message: 'Failed!!',
      }
    }
    return responseObj
  }

  return { handleUploadFiles }
}

export default useUploadS3Hook
